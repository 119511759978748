.lister-activation-banner {
  position: relative;
  z-index: map-get($zindex, none);
  padding: $lister-activation-banner-vertical-padding $lister-activation-banner-horizontal-padding;
  background-color: $lister-activation-banner-background-color;

  @include respond-to(phones) {
    padding: $lister-activation-banner-padding-top-mobile $lister-activation-banner-mobile-padding $lister-activation-banner-mobile-padding;
  }

  &::after,
  &::before {
    position: absolute;
    top: 0;
    z-index: map-get($zindex, below);
    display: block;
    width: 60%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
  }

  &::after {
    left: 0;
    background-image: url($lister-activation-banner-tear-left-icon-url);
  }

  &::before {
    right: 0;
    background-image: url($lister-activation-banner-tear-right-icon-url);
  }
}

.lister-activation-banner__close {
  position: absolute;
  top: $lister-activation-banner-close-button-offset;
  right: $lister-activation-banner-close-button-offset;
}

.lister-activation-banner__bottom-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  @include respond-to(tablets-up) {
    align-items: center;
    flex-direction: row;
  }
}

.lister-activation-banner__bottom-info-text {
  display: flex;
  flex-direction: column;
}

.lister-activation-banner__bottom-info-action {
  width: 100%;
  padding-top: $lister-activation-banner-text-top-padding;

  @include respond-to(tablets-up) {
    width: $lister-activation-banner-button-max-width;
    padding-top: 0;
    padding-left: $lister-activation-banner-text-left-padding;
  }
}

.lister-activation-banner__photos {
  display: flex;
  padding-bottom: $lister-activation-banner-photo-bottom-padding;
  justify-content: center;

  @include respond-to(tablets-up) {
    justify-content: space-between;
  }
}

.lister-activation-banner__photo-item {
  width: 100%;
  max-width: $lister-activation-banner-photo-mobile-width;

  @include respond-to(tablets-up) {
    max-width: $lister-activation-banner-photo-width;
  }

  &:not(:first-child) {
    margin-left: $lister-activation-banner-photo-min-gutter;
  }
}
