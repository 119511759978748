// TODO: Rework media queries to use `respond-to`
// after removing css from OneTrust's dashboard.
// Because breakpoints mismatch and it would cause
// weird overwrites here

// TODO: use `unit` helper to define sizes

#onetrust-consent-sdk #onetrust-banner-sdk {
  .ot-dpd-content .ot-dpd-desc, #onetrust-policy-text {
    color: palette(greyscale-level-2);
  }

  div,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a[href],
  #onetrust-accept-btn-handler,
  #onetrust-pc-btn-handler.cookie-setting-link,
  #onetrust-reject-all-handler,
  .onetrust-vendors-list-handler {
    -webkit-font-smoothing: antialiased;
  }

  #onetrust-policy-text,
  .ot-dpd-desc,
  .ot-b-addl-desc {
    font-size: 0.875em;
  }

  #onetrust-accept-btn-handler,
  #onetrust-reject-all-handler {
    font-size: 0.875em;
    min-width: 100%;
    border-radius: 6px;
    padding: 10px 12px;
  }

  #onetrust-policy-title,
  .ot-dpd-title {
    color: palette(greyscale-level-1);
  }

  a[href] {
    display: inline;
    font-weight: normal;
    text-decoration: underline;
  }

  .ot-sdk-container {
    max-width: 1280px;
    width: 100%;
    padding: 30px 20px;
  }

  #onetrust-policy {
    margin: auto;
  }

  #onetrust-policy-title {
    font-size: 1.125em;
  }

  .ot-dpd-title {
    font-size: 0.875em;
  }

  #onetrust-pc-btn-handler.cookie-setting-link {
    min-width: 100%;
    text-decoration: none;
    padding: 10px 12px;
    font-size: 0.875em;
    letter-spacing: 0;
    border-radius: 6px;
  }

  .onetrust-close-btn-ui.onetrust-lg {
    top: 30px;
  }

  .onetrust-vendors-list-handler {
    display: inline;
    text-decoration: underline;
    font-weight: normal;
  }

  #onetrust-button-group {
    display: block;
  }

  :not(.ot-dpd-desc)>.ot-b-addl-desc {
    display: none;
    border-right: none;
  }

  @media only screen and (max-width: 425px) {
    .onetrust-close-btn-ui {
      margin: 0;
      position: absolute;
      top: 0;
      right: 0;
    }

    .ot-sdk-container {
      padding: 30px 16px 20px;
    }

    #onetrust-policy-title {
      display: inline-block;
    }

    #onetrust-policy-text,
    .ot-dpd-desc,
    .ot-b-addl-desc {
      font-size: 0.875em;
    }
  }

  @media only screen and (min-width: 769px) {
    #onetrust-button-group {
      margin-right: 0;
    }
  }

  @media only screen and (min-width: 897px) {
    #onetrust-button-group-parent {
      right: 20px;
      width: 15%;
    }
  }

  @media only screen and (max-width: 896px) {
    .has-reject-all-button #onetrust-button-group {
      width: 100%;
      margin-top: 16px;
    }

    #onetrust-accept-btn-handler,
    #onetrust-reject-all-handler,
    #onetrust-pc-btn-handler.cookie-setting-link {
      padding: 12px;
      font-size: 1em;
      margin-top: 1em;
    }
  }

  @media only screen and (min-width: 1024px) {
    #onetrust-policy-text {
      border-right: none;
      padding-right: 2em;
    }
  }

  @media only screen and (min-width: 897px) and (max-width: 1023px) {
    &.vertical-align-content #onetrust-button-group-parent {
      left: auto;
    }
  }

  [data-theme='dark'] & {
    @include shadow(elevated);
    background-color: palette(greyscale-level-6);

    #onetrust-policy-title,
    #onetrust-policy-text,
    .ot-b-addl-desc,
    .ot-dpd-desc,
    .ot-dpd-title,
    #onetrust-policy-text *:not(.onetrust-vendors-list-handler),
    .ot-dpd-desc *:not(.onetrust-vendors-list-handler),
    #banner-options *,
    .ot-cat-header {
      color: palette(greyscale-level-3);
    }

    a[href],
    a[href] font,
    .ot-link-btn {
      color: palette(primary-default);
    }

    #onetrust-accept-btn-handler,
    #onetrust-reject-all-handler {
      background-color: palette(primary-default);
      border-color: palette(primary-default);
      color: palette(greyscale-level-6);
    }

    #onetrust-pc-btn-handler,
    #onetrust-pc-btn-handler.cookie-setting-link {
      color: palette(primary-default);
      border-color: palette(primary-default);
      background-color: palette(greyscale-level-6);
    }
  }

  &:not(.ot-iab-2) {
    #onetrust-button-group-parent {
      padding-right: 0 !important;
    }

    #onetrust-button-group {
      display: flex;
      flex-direction: column;
    }

    #onetrust-button-group #onetrust-accept-btn-handler {
      order: 1;
      margin: 0 !important;
    }

    #onetrust-button-group .cookie-setting-link {
      order: 2;
      margin: 8px 0 0 0 !important;
    }

    @media only screen and (min-width: 426px) and (max-width: 959px) {
      #onetrust-group-container {
        width: 100%;
      }

      #onetrust-button-group-parent {
        width: 100%;
        position: relative !important;
        transform: none !important;
        margin-left: 0;
        padding-left: 0 !important;
        padding-top: 24px;
      }
    }

    @media only screen and (min-width: 960px) and (max-width: 1199px) {
      #onetrust-group-container {
        width: 65%;
      }

      #onetrust-button-group-parent {
        width: 35% !important;
      }
    }

    @media only screen and (min-width: 1200px) {
      #onetrust-group-container {
        width: 75%;
      }

      #onetrust-button-group-parent {
        width: 25% !important;
      }
    }
  }
}
