.drop-off-point-map {
  position: absolute;
  width: 100%;
  height: 100%;
}

.drop-off-point-map__locker-icon {
  position: absolute;
  transform: translate(50%, -160%);
}

.drop-off-point-map__location-pin {
  position: absolute;
  z-index: map-get($zindex, location-pin);
  cursor: pointer;
  transform: translate(-50%, -100%);
}

.drop-off-point-map__location-pin-background {
  width: $drop-off-point-map-location-pin-medium-width;
  height: $drop-off-point-map-location-pin-medium-height;
}

.drop-off-point-map__location-pin-background--active {
  width: $drop-off-point-map-location-pin-active-width;
  height: $drop-off-point-map-location-pin-active-height;
}

.drop-off-point-map__location-pin--hovered {
  z-index: map-get($zindex, location-pin-active);
}

.drop-off-point-map__loader {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.drop-off-point-map__info-window {
  position: absolute;
  z-index: map-get($zindex, info-window);
  width: $pickup-point-map-info-window-width;
  height: $pickup-point-map-info-window-height;
  transform: translate(-50%, -132px);

}

.drop-off-point-map__info-window--active {
  transform: translate(-50%, -149px);
}
