$page-content-width: unit(329);
$new-again-vchoney-semibold-fontfamily: VCHoneySemiBoldDeck, "Helvetica Neue", "Helvetica-Neue", "Arial", sans-serif;
$new-again-color-1: #000000;
$new-again-color-2: #d8d8d8;
$new-again-color-3: #edffe9;
$new-again-color-4: #1a4552;
$new-again-border-radius: unit(2);

@mixin full-width-section() {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}

@mixin content-container() {
  box-sizing: border-box;
  width: 100%;
  max-width: $page-content-width;
  padding-right: unit(5);
  padding-left: unit(5);
  margin-right: auto;
  margin-left: auto;
}

.new-again__landing-page-container {
  @include content-container;
}

.new-again_introduction-section {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  overflow-x: hidden;
}

.new-again__introduction-title {
  font-weight: 500;
  font-size: unit(10);
  line-height: unit(11);
  color: palette(greyscale-level-1);
  margin: 0 auto;
  max-width: unit(83.75);
  padding-top: unit(4);
  text-align: center;
  font-family: $new-again-vchoney-semibold-fontfamily;

  @include respond-to(desktops) {
    padding-top: unit(11);
    max-width: 60%;
    font-size: unit(19);
    line-height: unit(20.5);
  }
}

.new-again__introduction-video-iframe {
  border: 0;
  aspect-ratio: 1.75;
  width: 100%;
  max-width: unit(265);
  border-radius: unit(4);
  margin: 0 auto;
}

.new-again__introduction-content {
  width: 100vw;
  margin: unit(7.5) auto unit(23.5);
  padding: unit(0) unit(5);
  box-sizing: border-box;
  text-align: center;

  @include respond-to(desktops) {
    margin-top: unit(13.25);
    margin-bottom: unit(24.75);
  }
}

.new-again__potential-section {
  position: relative;
  overflow-x: hidden;

  @include full-width-section;

  &::after {
    content: "";
    width: 100vw;
    height: 50%;
    position: relative;
    left: calc(-50vw + 50%);
    display: block;
    position: absolute;
    background: $new-again-color-3;
    bottom: 0;
    z-index: 1;
  }
}

.new-again__potential-section-inner {
  margin: 0 auto;
  width: 100%;
  padding-bottom: unit(28.75);

  @include respond-to(wide) {
    width: fit-content;
    display: flex;
    padding-bottom: unit(45);
  }
}

.new-again__potential-title {
  font-family: $new-again-vchoney-semibold-fontfamily;
  color: $new-again-color-1;
  font-size: unit(8);
  line-height: unit(9);
  text-align: left;
  padding: unit(0) unit(5) unit(10.75);
  width: unit(75);
  min-height: unit(22.75);

  @include respond-to(tablets) {
    width: 100%;
    min-height: 0;
  }

  @include respond-to(wide) {
    font-size: unit(12);
    line-height: unit(13);
    width: unit(88.75);
    padding: unit(13) unit(20.75) unit(0) unit(0);
    min-height: unit(45.25);
  }
}

.new-again__potential-content {
  margin-left: 0;

  @include respond-to(wide) {
    margin-left: 5.5vw;
  }
}

.new-again__potential-cards-wrapper {
  width: fit-content;
  display: block;
  gap: unit(2);
  margin-bottom: unit(0);
  padding: unit(0) unit(5);
  position: relative;
  z-index: 2;

  @include respond-to(wide) {
    overflow: hidden;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-bottom: unit(0);
    padding: 0;
  }
}

.new-again__introduction-description {
  color: palette(greyscale-level-1);
  margin: 0 auto;
  font-weight: 375;
  font-size: unit(3.5);
  line-height: unit(5);
  text-align: center;
  margin-top: unit(8);
  margin-bottom: unit(10.5);

  @include respond-to(desktops) {
    max-width: unit(210);
    font-size: unit(5.5);
    line-height: unit(7.5);
    margin-top: unit(17);
    margin-bottom: unit(13.75);
  }
}

.new-again__introduction-cta {
  margin: 0 auto;
  width: fit-content;

  .web_ui__Button__button {
    border-radius: $new-again-border-radius;

    @include respond-to(desktops) {
      padding-left: unit(7);
      padding-right: unit(7);
    }
  }

  .web_ui__Button__filled {
    background-color: $new-again-color-4;
  }

  .web_ui__Button__default {
    font-size: unit(3.5);

    @include respond-to(wide) {
      font-size: unit(4);
      height: unit(12.5);
    }
  }
}

.new-again__potential-card {
  width: unit(68.7);
  height: fit-content;

  @include respond-to(wide) {
    width: unit(100.5);
  }
}

.new-again__potential-card-image {
  overflow: hidden;
  width: unit(68.75);
  height: unit(84.5);
  border-radius: unit(3.75);

  @include respond-to(wide) {
    width: unit(100.5);
    height: unit(123.75);
    border-radius: unit(5);
  }
}

.new-again__potential-card-title {
  color: $new-again-color-1;
  font-weight: 580;
  font-size: unit(4);
  line-height: unit(5);
  margin: 0;
  padding-top: unit(4);
  padding-bottom: unit(5);

  @include respond-to(wide) {
    font-size: unit(5);
    line-height: unit(6.5);
    padding-top: unit(5.25);
    padding-bottom: unit(5.5);
  }
}

.new-again__slider-wrapper-item {
  padding-right: unit(5.25);

  @include respond-to(wide) {
    padding-right: unit(8.75);
  }
}

.new-again__potential-section-slider-container {
  width: 100%;
  position: relative;
  display: grid;
  grid-auto-flow: column;

  @include respond-to(desktops) {
    padding-left: unit(0);
  }
}

.ew-again__potential-section-slider-container::-webkit-scrollbar {
  display: none;
}

.new-again__potential-slider-buttons-container {
  display: none;
  position: relative;
  z-index: 2;

  @include respond-to(wide) {
    padding-top: unit(6);
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
  }
}

.new-again__slider-wrapper-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: unit(7.5);
  height: unit(7.5);
  border-radius: 50%;
  border: solid unit(0.375) $new-again-color-1;
  margin-right: unit(2);

  .web_ui__Icon__greyscale-level-1 svg {
    fill: $new-again-color-1;
    color: $new-again-color-1;
  }
}

.new-again__slider-wrapper-button.disabled {
  border: solid unit(0.375) $new-again-color-2;

  .web_ui__Icon__greyscale-level-5 svg {
    fill: $new-again-color-2;
    color: $new-again-color-2;
  }
}

.new-again__rotate-180 {
  transform: rotate(180deg);
}

.new-again_for-nowever-section {
  width: 100%;
  position: relative;
  color: $new-again-color-1;
  padding-bottom: unit(16);

  &::after {
    content: "";
    width: 100vw;
    height: 100%;
    position: relative;
    left: calc(-50vw + 50%);
    display: block;
    position: absolute;
    background: $new-again-color-3;
    bottom: 0;
  }

  > * {
    position: relative;
    z-index: 2;
  }

  @include respond-to(desktops) {
    text-align: center;
    padding-bottom: unit(25);
  }
}

.new-again__for-nowever-title {
  font-family: $new-again-vchoney-semibold-fontfamily;
  font-size: unit(8);
  line-height: unit(9);
  width: unit(49.25);
  margin-bottom: unit(9);

  @include respond-to(desktops) {
    font-size: unit(19);
    line-height: unit(20.5);
    width: unset;
    margin-bottom: unit(10.25);
  }
}

.new-again__for-nowever-description {
  font-weight: 375;
  font-size: unit(3.5);
  line-height: unit(5);
  margin-bottom: unit(13.5);

  @include respond-to(desktops) {
    max-width: unit(210);
    font-size: unit(5.5);
    line-height: unit(7.5);
    margin: 0 auto unit(22.5);
  }
}

.new-again__for-nowever-image {
  width: unit(84);
  max-width: 100%;
  aspect-ratio: 0.56;
  margin: 0 auto;

  @include respond-to(desktops) {
    width: 100%;
    aspect-ratio: unset;
    max-height: unit(177);
    border-radius: unit(4);
    overflow: hidden;
  }
}

.new-again__feel-new-section {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  color: $new-again-color-3;
  padding: unit(18.75) unit(0) unit(15);
  z-index: 0;

  &::after {
    content: "";
    width: 100vw;
    height: 100%;
    position: relative;
    left: calc(-50vw + 50%);
    display: block;
    position: absolute;
    background: palette(primary-dark);
    bottom: 0;
  }

  > * {
    position: relative;
    z-index: 2;
  }

  @include respond-to(desktops) {
    text-align: center;
    padding: unit(25) unit(0) unit(22.5);
  }
}

.new-again__feel-new-headline {
  font-family: $new-again-vchoney-semibold-fontfamily;
  margin: 0;
  font-size: unit(10);
  line-height: unit(11);
  margin: 0 auto unit(11);
  font-weight: 500;
  text-align: center;
  width: 90%;

  @include respond-to(wide) {
    margin: 0 auto unit(13);
    max-width: unit(264);
    line-height: unit(21);
    font-size: unit(19);
    width: 100%;
  }
}

.new-again__feel-new-cta {
  width: fit-content;
  margin: 0 auto;

  .web_ui__Button__button {
    border-radius: $new-again-border-radius;
    padding-left: unit(6.5);
    padding-right: unit(6.5);

    @include respond-to(desktops) {
      padding-left: unit(10.5);
      padding-right: unit(10.5);
    }
  }

  .web_ui__Button__filled {
    background-color: $new-again-color-3;
  }

  .web_ui__Button__primary.web_ui__Button__inverse.web_ui__Button__filled .web_ui__Button__label {
    color: palette(primary-dark);
  }

  .web_ui__Button__default {
    font-size: unit(3.5);
    line-height: unit(5.5);
    font-weight: 500;

    @include respond-to(wide) {
      font-size: unit(4);
      height: unit(12.5);
    }
  }
}
