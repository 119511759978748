// Navigation for categories list (dropdown, footer, etc)
//
// extends .nav and .nav-detailed
//

$catalog-dropdown-width: 780px;
$catalog-dropdown-max-height: calc(100vh - 150px);

.catalog-dropdown {
  width: $catalog-dropdown-width;
}

.catalog-dropdown__content {
  position: relative;
  max-height: $catalog-dropdown-max-height;
}

.catalog-dropdown__categories {
  width: 35%;
  padding-right: unit(3);
  margin-right: unit(3);
  border-right: unit(0.25) solid palette(greyscale-level-1, 0.08);
}

.catalog-dropdown__catalogs {
  width: 70%;
}

.catalog-dropdown__category {
  flex-wrap: wrap;
  display: none;

  &.active {
    display: flex;
  }
}

.catalog-dropdown__item {
  width: 50%;
  margin-bottom: unit(1);
}
