@use "sass:math";

.homepage {
  position: relative;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.homepage-block-page-header {
  height: 48px;
}

$header-tabs-height: 45px;

.homepage-tabs {
  height: $header-tabs-height;
  position: sticky;
  z-index: map-get($zindex, large);
  top: $header-total-height;
  background-color: palette(greyscale-level-6);

  // TODO: These styles are copied from .container class. Create reusable mixin for this
  width: 100%;
  max-width: 100%;
  padding-right: $gutter;
  padding-left: $gutter;

  @include respond-to(phones) {
    padding-right: map-get($grid-gutters, phones);
    padding-left: map-get($grid-gutters, phones);
  }

  @include respond-to(portables) {
    min-width: 0;
    width: auto;
  }

  @include respond-to(tablets) {
    margin: 0;
  }
}

.homepage-tabs__content {
  @include hide-scrollbar;

  display: flex;
  gap: 16px;
  margin: 0 auto;
  max-width: $page-width-wide;
  border-bottom: 1px solid palette-light(amplified-default, 0.08);
  overflow-y: scroll;
  scroll-behavior: smooth;
}

$homepage-top-banner-gap: 16px;

.homepage-top-banners {
  display: flex;
  flex-direction: column;
  gap: $homepage-top-banner-gap;
}

.homepage-top-banners > div:last-child {
  padding-bottom: $homepage-top-banner-gap;
}

$homepage-blocks-column-gap-phones: 8px;
$homepage-blocks-column-gap-tablets-up: 16px;

.homepage-blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: $homepage-blocks-column-gap-phones;

  @include respond-to(tablets-up) {
    column-gap: $homepage-blocks-column-gap-tablets-up;
  }
}

.homepage-blocks__item {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

@function get-column-width($columns, $gap) {
  $gaps: $gap * ($columns - 1);
  $total-columns-width: calc(100% - $gaps);

  @return calc($total-columns-width / $columns);
}

.homepage-blocks__item--one-fifth {
  width: get-column-width(2, $homepage-blocks-column-gap-phones);
  padding-bottom: 8px;

  @include respond-to(tablets-up) {
    padding-bottom: 16px;
  }

  @include respond-to(tablets) {
    width: get-column-width(3, $homepage-blocks-column-gap-tablets-up);
  }

  @include respond-to(desktops) {
    width: get-column-width(4, $homepage-blocks-column-gap-tablets-up);
  }

  @include respond-to(wide) {
    width: get-column-width(5, $homepage-blocks-column-gap-tablets-up);
  }
}
