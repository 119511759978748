$page-content-width: unit(281.5);

@mixin full-width-background($bg-color) {
  width: 100vw;
  position: absolute;
  left: calc(-50vw + 50%);
  content: "";
  display: block;
  background-color: $bg-color;
  height: 100%;
  z-index: -1;
  top: 0;
}

.accessibility-landingpage {
  display: block;
}

.accessibility-landingpage__first-section {
  margin: unit(16) 0;
  color: palette(greyscale-level-1);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond-to(tablets-up) {
    margin: unit(16) 0 unit(24.75);
  }
}

.accessibility-landingpage__first-section-title {
  width: fit-content;
  font-size: unit(10.5);
  font-weight: 500;
  line-height: unit(13);
  text-align: center;
  margin-bottom: unit(12);
}

.accessibility-landingpage__first-section-description {
  margin-top: 0;
  width: fit-content;
  font-size: unit(4);
  font-weight: 375;
  line-height: unit(6);
  text-align: center;
  margin-bottom: unit(16);

  @include respond-to(tablets-up) {
    max-width: unit(181.5);
    font-size: unit(6);
    line-height: unit(9);
    text-align: center;
  }
}

.accessibility-landingpage__first-section-image {
  overflow: hidden;
  width: 100%;
  border-radius: unit(5);
  
  @include respond-to(desktops) {
    height: unit(133);
  }
}

.accessibility-landingpage__second-section {
  margin-bottom: unit(10.5);
  color: palette(greyscale-level-1);
  display: flex;
  flex-direction: column;
  max-width: $page-content-width;
  margin-right: auto;
  margin-left: auto;

  @include respond-to(desktops) {
    margin-bottom: unit(28);
    flex-direction: row;
  }
}

.accessibility-landingpage__second-section-title {
  font-size: unit(8);
  font-weight: 500;
  line-height: unit(11.5);
  text-align: center;
  margin-bottom: unit(10);

  @include respond-to(desktops) {
    font-size: unit(10.5);
    line-height: unit(15);
    text-align: left;
    min-width: unit(95.75);
    max-width: unit(95.75);
    margin-right: unit(8);
  }
}

.accessibility-landingpage__second-section-cards {
  display: flex;
  flex-direction: column;
  gap: unit(5.5);

  @include respond-to(tablets-up) {
    flex-grow: 1;
    gap: unit(7.5);
  }
}

.accessibility-landingpage__second-section-card {
  border: 1px solid #bababa;
  border-radius: unit(6.75);
  padding: unit(2.5) unit(5) unit(8);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include respond-to(tablets-up) {
    padding: unit(12.5) unit(14) unit(0) unit(14);
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }
}

.accessibility-landingpage__second-section-card-image {
  min-width: unit(50);
  max-width: unit(50);
  height: unit(50);

  @include respond-to(tablets-up) {
    margin-bottom: unit(14);
  }
}

.accessibility-landingpage__second-section-card-text {
  margin-top: unit(2.5);

  @include respond-to(tablets-up) {
    flex-grow: 1;
    margin-top: 0;
    margin-left: unit(14);
    margin-bottom: unit(10);
  }
}

.accessibility-landingpage__second-section-card-title {
  font-size: unit(6);
  font-weight: 500;
  line-height: unit(8);
  margin-bottom: unit(5);

  @include respond-to(tablets-up) {
    font-size: unit(8);
    line-height: unit(9);
    margin-bottom: unit(7.5);
  }
}

.accessibility-landingpage__second-section-card-description {
  font-size: unit(4);
  font-weight: 375;
  line-height: unit(6);
}

.accessibility-landingpage__third-section {
  position: relative;
  display: block;
  color: palette(greyscale-level-6);
  z-index: 1;
  padding: unit(15) 0 unit(22.75);

  a {
    color: palette(greyscale-level-6);
  }

  &::after {
    @include full-width-background(palette(primary-dark));
  }

  @include respond-to(desktops) {
    max-width: $page-content-width;
    margin: 0 auto;
    display: flex;
    padding: unit(19.75) 0;
  }
}

.accessibility-landingpage__third-section-title {
  font-size: unit(8);
  font-weight: 500;
  line-height: unit(11.5);
  text-align: center;
  margin-bottom: unit(15);

  @include respond-to(tablets-up) {
    font-size: unit(10.5);
    line-height: unit(15);
    text-align: left;
    min-width: unit(95.75);
    max-width: unit(95.75);
    margin-right: unit(8);
  }
}

.accessibility-landingpage__third-section-description {
  display: block;
  margin-top: unit(0);

  @include respond-to(tablets-up) {
    margin-top: unit(2);
  }

  p {
    font-size: unit(4);
    font-weight: 375;
    line-height: unit(6);
    text-align: center;
    margin-top: unit(7);

    @include respond-to(tablets-up) {
      font-size: unit(5.5);
      line-height: unit(8);
      text-align: left;
      max-width: unit(178);
      margin-top: unit(8);
    }
  }
}

.accessibility-landingpage__fourth-section {
  width: 100%;
  max-width: unit(83.75);
  box-sizing: border-box;
  margin: 0 auto;
  color: #666;
  margin-bottom: unit(1);
  text-align: center;
  font-size: unit(3.5);
  line-height: unit(5);
  padding: unit(12.5) unit(0) unit(11);
  border-bottom: 1px solid #ddd;

  @include respond-to(tablets-up) {
    max-width: unit(320);
    padding: unit(10) unit(0) unit(8.75);
  }

  a {
    color: #666;
  }

  .accessibility-landingpage__fourth-section-body {
    box-sizing: border-box;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;

    @include respond-to(tablets-up) {
      max-width: unit(132.25);
    }
  }
}
