@mixin respond-to($media) {
  @if $media == phones {
    @media #{$phones-mq} {
      @content;
    }
  }

  @else if $media == portables {
    @media #{$tablets-mq}, #{$phones-mq} {
      @content;
    }
  }

  @else if $media == tablets {
    @media #{$tablets-mq} {
      @content;
    }
  }

  @else if $media == tablets-up {
    @media #{$tablets-up-mq} {
      @content;
    }
  }

  @else if $media == desktops {
    @media #{$desktops-mq} {
      @content;
    }
  }

  @else if $media == wide {
    @media #{$wide-mq} {
      @content;
    }
  }

  @else if $media == retina {
    @media #{$retina-mq} {
      @content
    }
  }
}
