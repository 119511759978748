.issue-details-photos {
  position: relative;
  height: $issue-details-photo-container-height-wide;

  @include respond-to(phones) {
    height: $issue-details-photo-container-height-phones;
  }

  @include respond-to(desktops) {
    height: $issue-details-photo-container-height;
  }

  @include respond-to(wide) {
    .is-wide & {
      height: $issue-details-photo-container-height-wide;
    }
  }

  .issue-details-thumbnail {
    display: block;
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    margin: 0;
    color: transparent;
    background-position: center center;
    background-size: cover;

    @include respond-to(phones) {
      border-radius: 0;
      width: 100%;
    }

    .i {
      &:before {
        @include center;
        position: absolute;
      }
    }

    &[data-photoid] {
      cursor: pointer;
    }
  }
}

.issue-details-photo {
  @include respond-to(phones) {
    width: 100%;
  }
}

.issue-details-photo,
.issue-details-photo--3 ~ .issue-details-photo--2 {
  position: absolute;
  top: 0;
  box-sizing: border-box;
  height: 50%;
  padding: 0;
  border-left: $issue-details-photo-border;

  &:only-child {
    .issue-details-thumbnail {
      margin: 0 auto;
    }
  }

  &:not(:only-child) {
    .issue-details-thumbnail {
      width: 100%;
    }
  }
}

.issue-details-photo:first-child {
  border-left: 0;
}

.issue-details-photo--1 {
  left: 0;
  height: 100% !important;

  &:not(:last-child) {
    width: 50%;
  }
}

.issue-details-photo--2 {
  left: 50%;
  width: 50%;
  height: 50%;

  &:last-child {
    height: 100%;
  }

  &:nth-last-child(4) {
    width: 25%;
  }
}

.issue-details-photo--3 {
  top: 50%;
  left: 50%;
  width: 25%;

  &:last-child {
    width: 50%;
  }

  &:nth-last-child(3) {
    top: 0;
    left: 75%;
  }
}

.issue-details-photo--3:last-child,
.issue-details-photo--3:nth-last-child(2),
.issue-details-photo--4,
.issue-details-photo--5 {
  border-top: $issue-details-photo-border;
}

.issue-details-photo--4 {
  left: 50%;
  width: 25%;
}

.issue-details-photo--4,
.issue-details-photo--5 {
  top: 50%;
}

.issue-details-photo--4:last-child,
.issue-details-photo--5 {
  left: 75%;
  width: 25%;
  border-left: $issue-details-photo-border;
}

.issue-details-actions {
  display: flex;
  justify-content: flex-end;

  @include respond-to(phones) {
    flex-direction: column;
  }
}

.issue-details-action {
  @include respond-to(phones) {
    width: 100%;
  }
}

.issue-items .web_ui__Cell__wide {
  padding-top: 10px;
}

.issue-preview-button .web_ui__Cell__wide {
  padding-top: 0;
}


