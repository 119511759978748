.inbox-page__mobile-card {
  @include respond-to(portables) {
    border-top: unit(0.25) solid palette(greyscale-level-1, 0.08);
    border-bottom: unit(0.25) solid palette(greyscale-level-1, 0.08);
  }

  @include respond-to(tablets) {
    margin: unit(-5);
  }

  @include respond-to(phones) {
    margin: unit(-5) unit(-2.5);
  }
}

.inbox-page__block {
  height: $inbox-card-height-desktop;
  min-height: $inbox-card-min-height;

  @include respond-to(portables) {
    height: $inbox-card-height-phone;
  }
}

.inbox-page__block--content {
  @include respond-to(desktops) {
    border-left: unit(0.25) solid palette(greyscale-level-1, 0.08);
  }
}

.inbox-page__block--sidebar {
  width: $inbox-sidebar-width;
  flex-shrink: 0;
}

.inbox-page__ad {
  padding-left: unit(4);
  width: $ad-sidebar-max-width;
}

.inbox-page__ad:empty, .conversation-list__ad:empty {
  display: none;
}

.inbox-page__container {
  margin-bottom: -$gutter;
}

.inbox-page__recipent-list {
  flex: 1 0 1px;
  overflow: auto;
}

.conversation-list__ad {
  min-height: $ad-inbetween-mobile-small-min-height;
}

.conversation-header a {
  text-decoration: none;
}
