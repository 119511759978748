
// Photo recommendations
.seller-insights-photo-recommendations__item {
  box-sizing: border-box;
  display: inline-block;
  position: relative; 
  width: max-content; 
  height: max-content; 
}

@mixin grid-container($wide-columns) {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(unit(48), max-content)); /* Image size */
  gap: 10px;
  justify-content: center;

  @include respond-to(wide) {
    grid-template-columns: repeat($wide-columns, 1fr);
  }
}

.grid-container--4 {
  @include grid-container(4);
}

.grid-container--6 {
  @include grid-container(6);
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(unit(48), max-content)); /* Image size */
  gap: 10px;
  justify-content: center;

  @include respond-to(wide) {
    grid-template-columns: repeat(4, 1fr);
  }
}

// Seller insights blocks and tips
.seller-blocks-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(unit(65), 1fr)); /* Allows items to grow */
  gap: unit(4);
  width: 100%; 
}

.seller-insights-atom {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: unit(7);
  min-height: unit(45);

  &.seller-insights-tips {
    justify-content: space-between;
    gap: 0;
  }

  &.seller-insights-block-without-tips {
    gap: unit(3);
    padding-bottom: unit(4) !important;
    min-height: 0;
  }

  @include respond-to(portables) {
    gap: unit(8);
    min-height: unit(54);
  }
}

.seller-insights-tips-icon {
  background-color: palette-light(ca10);
  width: unit(10);
  height: unit(10);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

// Sustainability impact
.sustainability-card {
  width: 28vw;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  padding: 20px;
  flex-shrink: 0;
  height: 100%;

  @include respond-to(tablets) {
    flex-direction: column;
  }

  @include respond-to(phones) {
    width: 100vw;
  }
}

.sustainability-card__content {
  flex-grow: 1;
  min-width: 0;
  max-height: 100%;
}

.sustainability-impact-container {
  display: flex;
  overflow-x: auto;
  width: 100%;
  gap: unit(4);
}
