@use "sass:math";

.thumbnails-layout-container {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  gap: unit(4);
}

.thumbnails-big-image {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.thumbnails-small-image {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.thumbnails-layout-mobile-container {
  display: flex;
  flex-direction: row;
  gap: unit(4);
  flex-grow: 1;
}

.single-thumbnail-container {
  .thumbnail-title-wrapper {
    height: $thumbnail-title-wrapper-height;
  }

  .single-thumbnail-container-body {
    width: 100%;
  }
}

.small-round-thumbnail-title {
  width: $round-thumbnail-title-width;

  @include respond-to(wide) {
    width: unset;
  }
}

.thumbnail-image--small-round > div {
  border: 1px solid palette(greyscale-level-5);
}

.thumbnail-image > div {
  width: 100%;
  min-width: 140px;
}

@media only screen and (max-width: 720px) {
  .big-thumbnail-title {
    width: $big-thumbnail-title-width-mobile!important;
  }
}

.homepage-layouts-text-truncation {
  @include multiline-ellipsis(2);
}

.wide-banners-layout-container {
  display: grid;
  width: 100%;
}

.narrow-banners-layout-container {
  display: flex;
  gap: unit(4);

  @include respond-to(wide) {
    width: 100%;
    flex-wrap: nowrap;
  }
}

.block-banner__container {
  height: $banner-container-height;
  position: relative;
  border-radius: 12px;
  overflow: hidden;

  .block-banner__info {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    border-radius: unit(1);

    .block-banner__info-content {
      align-content: space-between;
      display: grid;
      height: $banner-container-height - (2 * unit(8));
      padding: unit(8) unit(4) unit(8) unit(8);

      @include respond-to(phones) {
        height: $banner-mobile-container-height - (2 * unit(4));
        padding: unit(4);
      }
    }
  }

  .block-banner__foreground {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;

    > div {
      border-top-right-radius: unit(1);
      border-bottom-right-radius: unit(1);
    }
  }

  .block-banner__foreground--narrow-desktop {
    width: $foreground-narrow-desktop-width;
  }

  .block-banner__foreground--narrow-tablet {
    width: $foreground-narrow-tablet-width;
  }

  .block-banner__foreground--narrow-mobile {
    width: $foreground-narrow-mobile-width;
  }

  .block-banner__foreground--wide-desktop {
    width: $foreground-wide-desktop-width;
  }

  .block-banner__foreground--wide-tablet {
    width: $foreground-wide-tablet-width;
  }

  .block-banner__foreground--wide-mobile {
    width: $foreground-wide-mobile-width;
  }

  @include respond-to(phones) {
    height: $banner-mobile-container-height;
  }
}

.block-banner__title {
  @include multiline-ellipsis(2);
  text-overflow: ellipsis;
  max-height: $banner-title-height;
}

.block-banner__title--phones {
  max-height: $banner-mobile-title-height;
}

.block-banner__description {
  @include multiline-ellipsis(4);
  text-overflow: ellipsis;
  height: $banner-description-height;
}

.block-banner__description--phones {
  height: $banner-mobile-description-height;
}

.block-banner__header-narrow-desktop {
  padding-right: $foreground-narrow-desktop-width - (2 * unit(4));
}

.block-banner__header-narrow-tablet {
  padding-right: $foreground-narrow-tablet-width - (2 * unit(4));
}

.block-banner__header-narrow-mobile {
  padding-right: $foreground-narrow-mobile-width - (2 * unit(4));
}

.block-banner__header-wide-desktop {
  padding-right: $foreground-wide-desktop-width - (2 * unit(4));
}

.block-banner__header-wide-tablet {
  padding-right: $foreground-wide-tablet-width - (2 * unit(4));
}

.block-banner__header-wide-mobile {
  padding-right: $foreground-wide-mobile-width - (2 * unit(4));
}

.block-banner__container--narrow {
  width: 75vw;

  @include respond-to(wide) {
    flex: 0 0 calc((100% - #{unit(4)}) / 2);
  }

  @include respond-to(phones) {
    width: 85vw;
  }
}

.block-banner__cta {
  overflow: hidden;
}

.horizontal-rows-block-container {
  display: grid;
  justify-content: space-between;
  gap: unit(2);
  grid-template-areas:
    'rowElement1 rowElement3 rowElement5 rowElement7'
    'rowElement2 rowElement4 rowElement6 rowElement8';
}

.single-row-element a {
  border-radius: 3px;
}

.single-row-element img {
  border-radius: 3px 0 0 3px;
}

.horizontal-rows-desktop {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: unit(4);
}

.row-element-mobile {
  width: 220px;
}

.row-element-1 {
  grid-area: rowElement1;
}

.row-element-2 {
  grid-area: rowElement2;
}

.row-element-3 {
  grid-area: rowElement3;
}

.row-element-4 {
  grid-area: rowElement4;
}

.row-element-5 {
  grid-area: rowElement5;
}

.row-element-6 {
  grid-area: rowElement6;
}

.row-element-7 {
  grid-area: rowElement7;
}

.row-element-8 {
  grid-area: rowElement8;
}

.item-box-information-cell {
  padding: unit(2) 0;
  height: unit(18.5);
  overflow: hidden;
}
