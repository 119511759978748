$profile-sidebar-width: 300px;

.profile {
  margin: 0 (-(map-get($grid-gutters, phones)));

  @include respond-to(tablets-up) {
    display: flex;
    margin: $gutter 0;
  }
}

.profile__items-wrapper {
  flex: 1 1 auto;

  @include respond-to(tablets-up) {
    padding: unit(0.5);
  }
}

.profile__user-description {
  @include word-wrap;
  word-break: normal;
}

.profile__user-info-panel {
  width: unit(90);
  max-width: 100%;
  box-sizing: border-box;
}

.profile__user-info-panel__badges {
  width: unit(90);
  box-sizing: border-box;
}

.sticky-panel__sticky-with-tabs {
  @include hide-scrollbar;

  top: $header-total-height + unit(5);
  position: sticky;
  overflow: auto;
  max-height: calc(95vh - $header-total-height);
}