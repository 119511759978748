/* -----------------------------------------------------------------------------
 * Banners
 * ---------------------------------------------------------------------------*/

// Advertisement classes used in React

.ad-content, .admock-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: auto;
  // make sure ads don't go on top of content with their crazy z-indexes
  position: relative;
  z-index: map-get($zindex, small);

  @include respond-to(portables) {
    align-items: center;
    justify-content: center;
  }
}

.ad-placeholder {
  position: absolute;
  padding-top: $ad-info-height;
  top: 0;
  left: 0;
}

.ad-placeholder__text {
  position: absolute;
  // Hardcoded position because this text
  // has distinct position on the image
  height: 24px;
  width: 363px;
  top: 231px;
  left: 501px;
  //
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ad-placeholder--leaderboard {
  top: $half-gutter;
  width: $ad-leaderboard-max-width;
  height: $ad-leaderboard-max-height;

  @include respond-to(portables) {
    width: $ad-leaderboard-mobile-max-width;
    height: $ad-leaderboard-mobile-max-height;
  }
}

.ad-container:not(.ad-container--rendered) .ad-placeholder + .ad-content,
.ad-container.ad-container--rendered .ad-placeholder {
  opacity: 0;
}

.ad-placeholder--leaderboard + .ad-content {
  justify-content: center;
  height: $ad-leaderboard-max-height;

  @include respond-to(portables) {
    height: $ad-leaderboard-mobile-max-height;
  }
}

.ad-info, .admock-info {
  padding-bottom: unit(1);
  text-align: left;
  line-height: 0;

  @include respond-to(portables) {
    text-align: center;
  }
}

.ad-container, .admock-container {
  overflow: hidden;
  position: relative;
  margin: auto;
}

.ad-container--skyscraper, .ad-container--rectangle,
.admock-container--skyscraper, .admock-container--rectangle {
  max-width: $ad-sidebar-max-width;
  max-height: $ad-sidebar-max-height;

  @include respond-to(portables) {
    max-height: none;
  }
}

.ad-container--leaderboard, .admock-container--leaderboard {
  display: flex;
  padding-top: $half-gutter;
  padding-bottom: $half-gutter;
  text-align: center;
  max-width: $ad-leaderboard-max-width;
  max-height: $ad-leaderboard-max-height;
  min-height: 70px;
  line-height: initial;

  @include respond-to(portables) {
    max-width: $ad-leaderboard-mobile-max-width;
    max-height: $ad-leaderboard-mobile-max-height;
  }
}

.ad-container--inbetween, .admock-container--inbetween {
  max-width: $ad-inbetween-max-width;
  height: $ad-inbetween-height;
  text-align: center;

  @include respond-to(desktops) {
    .ad-content, .admock-content {
      display: inline-flex;
    }
  }

  @include respond-to(portables) {
    max-width: $ad-inbetween-mobile-max-width;
    height: auto;
  }
}

// Place it below `.ad-container` styles
// so it would not be overwriten to
// position: relative

.ad-sticky, .admock-sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: map-get($zindex, medium);
}

@include respond-to(desktops) {
  .ad-sidebar, .admock-sidebar {
    overflow-x: visible;
    overflow-y: clip;

    &:not(:first-child) {
      margin-top: unit(20);
    }
  }
}

@include respond-to(portables) {
  .ad-sidebar, .admock-sidebar {
    margin-top: unit(4);
  }

  .ad-sidebar.ad-sticky, .admock-sidebar.admock-sticky {
    position: static;
  }
}

.admock {
  width: 100%;
  height: 100%;
  background: palette(primary-default);
  content: 'Admock';
}

.admock--leaderboard {
  height: $ad-leaderboard-max-height - $ad-info-height;
  width: $ad-leaderboard-max-width;

  @include respond-to(portables) {
    height: $ad-leaderboard-mobile-max-height - $ad-info-height;
    width: $ad-leaderboard-mobile-max-width;
  }
}

.admock--skyscraper, .admock--rectangle {
  height: $ad-sidebar-max-height - $ad-info-height;
  width: $ad-sidebar-max-width;
}

@include respond-to(portables) {
  .admock--rectangle {
    height: unit(62.5);
  }
}

.admock--inbetween {
  width: $ad-inbetween-max-width;
  height: $ad-inbetween-height;

  @include respond-to(portables) {
    width: $ad-inbetween-mobile-max-width;
  }
}