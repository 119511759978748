
.checkout {
  display: flex;
  flex-direction: column;
}

.checkout-container {
  padding: $checkout-container-phones-vertical-padding
    $checkout-container-horizontal-padding;

  @include respond-to(tablets-up) {
    padding: $checkout-container-vertical-padding 0;
  }
}

.checkout__summary {
  @include sticky;
  top: $checkout-summary-top-padding;
  height: 0;
  max-width: $checkout-summary-max-width;
  min-width: $checkout-summary-min-width;
}

.single-checkout__grid {
  display: grid;
  grid-template-columns: minmax(320px,800px) minmax(320px,400px);
  column-gap: 24px;
  width: 100%;
}

.checkout__grid {
  display: grid;
  grid-template-columns: minmax(320px,800px) minmax(320px,400px);
  column-gap: 24px;
  width: 100%;

  @include respond-to(phones) {
    display: flex;
    width: 100%;
  }
}

.checkout__notification {
  position: sticky;
  right: 0;
  bottom: $checkout-notification-bottom;
  left: 0;
  z-index: map-get($zindex, notification);
  max-width: $checkout-notification-max-width;
  margin: 0 $checkout-notification-margin-horizontal-phones;
  align-self: center;

  @include respond-to(tablets-up) {
    margin: 0 $checkout-notification-margin-horizontal-tablets-up;
  }
}

.checkout__shipping__loader {
  @include stretch(50%, null, null, 50%);

  transform: translate(-50%, -50%);
}

.checkout__cvv-handling-modal__input {
  width: 85px;
  
  input {
    padding: 0;
  }
}

.checkout__order-summary-and-pay__divider {
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  background: white;
}

.checkout__pay-button__container {
  display: flex;
  background: white;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}