.shipping-fees-applied-banner--sticky {
  position: sticky;
  right: 0;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: map-get($zindex, medium);
  background: $color-background-white;
  top: $header-total-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shipping-fees-applied-banner--static {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  padding: 5px;
  box-sizing: border-box;
}

$header-tabs-height: 45px;
$header-total-with-tabs: $header-total-height + $header-tabs-height;

.shipping-fees-applied-banner--sticky-with-tabs {
  top: $header-total-with-tabs;
}
