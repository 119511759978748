.safety_header-title {
  @include type(
    map-get(map-get(map-get($responsive-text-types, large), heading), mobile)
  );

  @include respond-to(tablets-up) {
    @include type(
      map-get(map-get(map-get($responsive-text-types, large), heading), desktop)
    );
  }
}

.safety_header-body {
  @include type(
    map-get(map-get(map-get($responsive-text-types, large), body), mobile)
  );

  @include respond-to(tablets-up) {
    @include type(
      map-get(map-get(map-get($responsive-text-types, large), body), desktop)
    );

    font-weight: inherit;
  }

  font-weight: inherit;
}

.safety_facts-block-title {
  @include type(
    map-get(map-get(map-get($responsive-text-types, small), heading), mobile)
  );

  @include respond-to(tablets-up) {
    @include type(
      map-get(map-get(map-get($responsive-text-types, small), heading), desktop)
    );
  }
}

.safety_fact-title {
  @include type(
    map-get(map-get(map-get($responsive-text-types, small), heading), mobile)
  );

  @include respond-to(tablets-up) {
    @include type(
      map-get(map-get(map-get($responsive-text-types, small), heading), desktop)
    );
  }
}

.safety_fact-title-small {
  @include type(
    map-get(map-get(map-get($responsive-text-types, large), title), mobile)
  );

  @include respond-to(tablets-up) {
    @include type(
      map-get(map-get(map-get($responsive-text-types, large), title), desktop)
    );
  }
}

.safety_body {
  @include type(
    map-get(map-get(map-get($responsive-text-types, small), body), mobile)
  );

  @include respond-to(tablets-up) {
    @include type(
      map-get(map-get(map-get($responsive-text-types, small), body), desktop)
    );

    font-weight: inherit;
  }

  font-weight: inherit;
}
