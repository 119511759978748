.u-desktops-only {
  @include respond-to(portables) { display: none !important; }
}

.u-phones-only {
  @include respond-to(tablets) { display: none !important; }
  @include respond-to(desktops) { display: none !important; }
}

.u-mobiles-only {
  @include respond-to(desktops) { display: none !important; }
}

.u-tablets-only {
  @include respond-to(phones) { display: none !important; }
  @include respond-to(desktops) { display: none !important; }
}

.u-tablets-up-only {
  @include respond-to(phones) { display: none !important; }
}
