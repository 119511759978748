@use "sass:math";

@import 'abstracts/index';
@import 'layout/grid';

.faq {
  @extend .grid8;
  @extend %GRID-PORTABLES12;

  @include respond-to(phones) {
    padding-top: $half-gutter;
  }

  .faq__category {
    @include font-size($font-size-default);
    box-sizing: border-box;
    width: percentage(1 * 0.5);
    height: auto;
    max-width: percentage(1 * 0.5);
    padding: unit(6) unit(3);
    margin: 0;
    color: palette(greyscale-level-2);
    text-align: center;
    border: $box-line;
    border-top: 0;
    border-left: 0;

    @include respond-to(tablets-up) {
      width: percentage(math.div(1, 3));
      max-width: percentage(math.div(1, 3));
    }

    &:nth-child(3n + 3) {
      border-right: 0;
    }

    &:nth-last-child(-n + 3) {
      border-bottom: 0;
    }

    &:nth-child(3n) {
      @include respond-to(phones) {
        border-right: 1px solid $box-line-color;
      }
    }
  }

  .table-wrapper {
    overflow: auto;
  }

  .faq-search {
    display: none;

    @include respond-to(desktops) {
      display: block;
    }
  }
}

.faq__navigation--secondary {
  @include respond-to(portables) {
    display: none;
  }
}

.faq__categories {
  display: flex;
  flex-wrap: wrap;
}

.faq__category-title {
  margin-top: unit(3);
  word-break: break-all;
}

.faq__related {
  .is-webview & {
    @include font-size($font-size-medium);
  }
}

.faq__related-item {
  @include font-smoothing();
  margin: $quarter-gutter 0;
  list-style: none !important;

  .is-webview & {
    font-weight: $font-weight-demibold;
  }
}
