$page-content-width: unit(319);
$digital-product-passport-vchoney-semibold-fontfamily: VCHoneySemiBoldDeck, 'Helvetica Neue',
  'Helvetica-Neue', 'Arial', sans-serif;
$digital-product-passport-color-1: #000000;
$digital-product-passport-color-2: #edffe9;
$digital-product-passport-color-3: #1a4552;
$border-radius: unit(5);

.digital-product-passport_header-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: unit(19) auto 0 auto;
  text-align: center;
  max-width: unit(210);

  @include respond-to(phones) {
    margin-top: unit(11);
  }

  span {
    font-size: 2rem;
    line-height: 3.6rem;
    font-weight: 600;
    color: $digital-product-passport-color-1;
  }

  h1 {
    font-size: 7.6rem;
    line-height: 8.2rem;
    font-family: $digital-product-passport-vchoney-semibold-fontfamily;
    color: $digital-product-passport-color-1;
    margin: unit(4) 0;

    @include respond-to(phones) {
      font-size: 5rem;
      line-height: 6rem;
    }
  }

  p {
    font-size: 2rem;
    line-height: 2.8rem;
    color: $digital-product-passport-color-1;

    @include respond-to(phones) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
}

.digital-product-passport_preview-section {
  position: relative;
  left: calc(-50vw + 50%);
  width: 100vw;
  margin-top: unit(38);
  overflow-x: clip;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: unit(36);
    width: 100vw;
    background-color: palette(greyscale-level-5);
  }

  .digital-product-passport_preview {
    position: relative;
    z-index: 1;
    display: grid;
    min-height: unit(145);
    grid-template-columns: 6fr 5fr;
    margin: 0 auto;
    max-width: $page-content-width;
    background-color: $new-again-color-4;
    border-radius: $border-radius;
    padding: 0 unit(6.5);
    box-sizing: border-box;

    @include respond-to(phones) {
      display: flex;
      flex-direction: column;
      border-radius: 0;
    }
  }

  .digital-product-passport_preview-image {
    margin: 0 auto;

    .digital-product-passport_preview-image-wrapper {
      margin-top: unit(-16);
      width: 100%;
      max-width: unit(120);
      aspect-ratio: 120 / 140;
      transform: rotate(-3.68deg);

      @include respond-to(phones) {
        position: relative;
        left: 0;
        top: 0;
      }
    }

    .web_ui__Image__image {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius;
    }

    .digital-product-passport_swoosh {
      position: absolute;
      background-size: cover;
    }

    .digital-product-passport_top-left {
      top: -41px;
      left: -85px;
      width: 188px;
      height: 211px;
    }

    .digital-product-passport_top-right {
      top: 95px;
      right: -70px;
      width: 107px;
      height: 79px;
    }

    .digital-product-passport_bottom-right {
      bottom: -77px;
      right: -73px;
      width: 171px;
      height: 142px;
    }

    .digital-product-passport_bottom-left {
      bottom: -17px;
      left: -111px;
      width: 147px;
      height: 74px;
    }
  }

  .digital-product-passport_item-details {
    display: flex;
    margin: 0 unit(20) 0 unit(16);
    flex-direction: column;
    align-items: flex-start;
    padding: unit(24) 0 unit(32) 0;

    @include respond-to(phones) {
      margin: 0;
      align-items: center;
      padding: unit(16) 0 unit(24) 0;
    }
  }

  p {
    color: rgba($new-again-color-3, 0.8);
    font-size: 2rem;
    line-height: 2.4rem;

    @include respond-to(phones) {
      text-align: center;
    }
  }

  h2 {
    font-family: $new-again-vchoney-semibold-fontfamily;
    color: $new-again-color-3;
    font-weight: 600;
    font-size: 4.8rem;
    line-height: 5.2rem;
    margin-top: unit(2);

    @include respond-to(phones) {
      text-align: center;
      margin-top: 0;
    }
  }

  button {
    width: auto;
    background-color: $new-again-color-3;
    padding: 0 unit(13);
    margin-top: unit(24);

    @include respond-to(phones) {
      margin-top: unit(16);
    }

    span {
      color: $new-again-color-4;
    }
  }

  .digital-product-passport_tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: unit(3);
    margin-top: unit(8);

    @include respond-to(phones) {
      margin-top: unit(6);
      justify-content: center;
    }
  }

  .digital-product-passport_tag {
    padding: unit(1.625) unit(3.5);
    background-color: rgba($new-again-color-3, 0.15);
    border-radius: 999px;

    span {
      color: $new-again-color-3;
      font-size: 1.8rem;
      line-height: 2.8rem;
    }
  }
}

.digital-product-passport_how-it-works-section {
  padding: unit(32) 0;
  position: relative;
  left: calc(-50vw + 50%);
  width: 100vw;
  background-color: palette(greyscale-level-5);
  box-sizing: border-box;

  @include respond-to(phones) {
    padding: unit(16) unit(5) unit(24) unit(5);
  }

  .digital-product-passport_container {
    max-width: $page-content-width;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    font-family: $new-again-vchoney-semibold-fontfamily;
    color: palette(greyscale-level-1);
    font-weight: 600;
    font-size: 4.8rem;
    line-height: 6rem;
    text-align: center;

    @include respond-to(phones) {
      font-size: 3.6rem;
      line-height: 4.8rem;
    }
  }

  .digital-product-passport_steps {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: unit(16);
    gap: unit(8);
    min-height: unit(115);

    @include respond-to(phones) {
      display: flex;
      flex-direction: column;
      margin-top: unit(8);
      gap: unit(6);
      min-height: unset;
    }
  }

  .digital-product-passport_step {
    display: flex;
    flex-direction: column;
    background-color: palette(greyscale-level-6);
    border-radius: $border-radius;
    padding: unit(12) unit(9) unit(8) unit(9);

    @include respond-to(phones) {
      padding: unit(9) unit(8);
    }

    h3 {
      color: palette(greyscale-level-1);
      font-size: 2rem;
      line-height: 2.8rem;
      font-weight: 500;
      margin-top: unit(9);
    }

    p {
      margin-top: unit(6);
      font-size: 1.6rem;
      line-height: 2.4rem;

      @include respond-to(phones) {
        margin-top: unit(4);
      }
    }

    img {
      width: unit(40);
      height: unit(40);
    }
  }

  .digital-product-passport_app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: unit(16);

    @include respond-to(phones) {
      margin-top: unit(12);
    }

    p {
      color: palette(primary-extra-dark);
      font-size: 2rem;
      line-height: 2.8rem;
      text-align: center;
    }

    .digital-product-passport_app-buttons {
      display: flex;
      justify-content: center;
      margin-top: unit(8);
    }
  }
}

.digital-product-passport_sell-section {
  position: relative;
  left: calc(-50vw + 50%);
  width: 100vw;
  background-color: $digital-product-passport-color-3;

  @include respond-to(phones) {
    padding: unit(16) 0 unit(24) 0;
  }

  .digital-product-passport_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto;
    max-width: $page-content-width;
    height: 100%;

    @include respond-to(phones) {
      display: flex;
      flex-direction: column;
    }
  }

  .digital-product-passport_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: unit(100);

    @include respond-to(phones) {
      align-items: center;
      padding: 0 unit(5);
    }

    h2 {
      font-family: $new-again-vchoney-semibold-fontfamily;
      color: $digital-product-passport-color-2;
      font-weight: 600;
      font-size: 4.8rem;
      line-height: 5.2rem;

      @include respond-to(phones) {
        text-align: center;
        font-size: 3.6rem;
        line-height: 4.8rem;
      }
    }

    p {
      margin-top: unit(6);
      color: $digital-product-passport-color-2;
      font-size: 2rem;
      line-height: 2.8rem;

      @include respond-to(phones) {
        text-align: center;
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }

  .digital-product-passport_buttons {
    button,
    a {
      background-color: $digital-product-passport-color-3;
      padding: 0 unit(13);
      border: none;

      &:first-child {
        background-color: $digital-product-passport-color-2;

        span {
          color: $digital-product-passport-color-3;
        }
      }

      span {
        color: $digital-product-passport-color-2;
      }
    }
  }

  .digital-product-passport_desktop-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: unit(5);
    margin-top: unit(16);

    @include respond-to(phones) {
      display: none;
    }
  }

  .digital-product-passport_mobile-buttons {
    display: none;
    gap: unit(3);
    max-width: unit(54);
    margin: unit(6) auto 0 auto;
    flex-direction: column;

    @include respond-to(phones) {
      display: flex;
    }
  }

  .digital-product-passport_right {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: unit(4);

    @include respond-to(phones) {
      margin-top: unit(4);
    }

    &::after,
    &::before {
      position: absolute;
      left: 0;
      right: 0;
      height: unit(46);
      content: '';
      z-index: 1;
    }

    &::after {
      top: 0;
      background: linear-gradient(
        180deg,
        $digital-product-passport-color-3,
        rgba($digital-product-passport-color-3, 0)
      );
    }

    &::before {
      bottom: 0;
      background: linear-gradient(
        0deg,
        $digital-product-passport-color-3,
        rgba($digital-product-passport-color-3, 0)
      );
    }
  }

  .digital-product-passport_carousel {
    overflow: hidden;
    height: unit(145);
  }

  .digital-product-passport_carousel-item {
    margin-bottom: unit(10);

    &:last-child {
      margin-bottom: 0;
    }

    img {
      display: block;
      width: unit(46);
      height: unit(59);
      border-radius: $border-radius;
      background-color: purple;
    }
  }

  .digital-product-passport_carousel-item-image {
    position: relative;
  }

  .digital-product-passport_carousel-item-favourite {
    position: absolute;
    width: unit(9.5);
    height: unit(9.5);
    border-radius: 50%;
    background-color: palette(greyscale-level-6);
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: unit(2.5);
    right: unit(3);
  }

  .digital-product-passport_carousel-item-content {
    margin-top: unit(3);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      color: $digital-product-passport-color-2;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }
}
