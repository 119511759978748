// Hero

.advertising-page {
  margin: -$gutter 0;
}

.advertising__hero {
  position: relative;
  width: 100vw;
  background-color: palette(primary-default);
  overflow: hidden;
  left: calc(50% - 50vw);

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: $tear-height;
    background: palette(greyscale-level-6);
    -webkit-mask: $tear-horizontal-mask;
    mask: $tear-horizontal-mask;

    @include respond-to(portables) {
      transform: rotateY(180deg);
    }
  }
}

.advertising__hero--container {
  width: 100%;
  display: flex;
}

.advertising__hero--text, .advertising__hero--image-container {
  margin-left: calc(1/12) * 100%;
  width: calc(5/12) * 100%;
}

.advertising__hero--image-container {
  position: relative;
}

.advertising__hero--image {
  position: absolute;
  top: 0;
  left: 0;
  min-width: unit(176.5);
  min-height: 100%;
  aspect-ratio: calc(unit(28) / unit(19));
}

.advertising__hero--description {
  font-size: unit(5);
  line-height: unit(8);
  font-weight: 400;

  @include respond-to(portables) {
    font-size: unit(4);
    line-height: unit(5.5);
  }
}

// Mobile Hero

.advertising__mobile-hero--image-container {
  position: relative;
  width: 100%;
  height: unit(68.75);
  overflow: hidden;
}

.advertising__mobile-hero--image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: unit(115);
}

@include respond-to(phones) {
  .advertising__mobile-hero--text {
    padding: 0 unit(2);
  }
}

// Form sections

.advertising__form-container {
  width: calc(8/12) * 100%;
  max-width: unit(160);
  margin: 0 auto;
  counter-reset: advertising-form-counter;

  @include respond-to(portables) {
    width: 100%;
    max-width: none;
  }

  @include respond-to(phones) {
    width: calc(100% - unit(2));
  }
}

.advertising__form-section--title {
  padding: unit(6) 0;
}

.advertising__form-section--chevron {
  padding: unit(6) unit(6) unit(6) 0;
}

.advertising__form-section--badge-container {
  padding: unit(6) 0 unit(6) unit(6);
}

@include respond-to(portables) {
  .advertising__form-section--title {
    padding: unit(4) 0;
  }

  .advertising__form-section--chevron {
    padding: unit(4) unit(4) unit(4) 0;
  }

  .advertising__form-section--badge-container {
    padding: unit(4) 0 unit(4) unit(4);
  }
}

.advertising__form-section--content {
  margin-bottom: unit(-2);
}

.advertising__form-section--badge-increment {
  counter-increment: advertising-form-counter;

  &::before {
    content: counter(advertising-form-counter);
  }
}

.advertising__form-input--textarea-a11y {
  height: 0;
  width: 0;
  overflow: hidden;
}

.advertising__form-container .web_ui__Card__card {
  .web_ui__Cell__cell, .web_ui__Input__input {
    background: transparent;
  }
}

.advertising__form-container .input-dropdown .web_ui__Card__elevated {
  box-shadow: 0 4px 16px rgba(var(--greyscale-level-1), 0.24);
}

.advertising__form-container .pile {
  max-height: 224px;
}

