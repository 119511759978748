/* Following CSS classes are used by copy-writers in the FAQ content pages
  - .c-text
  - .c-text--heading
  - .default-note
  - .faq-content
  - .faq-content--accordion
  - .important-note
  - .inline-block
  - .nav
  - .nav-item
  - .nav-item.active
  - .nav-link
  - .nav-tabs
  - .nav-tabs-horizontal
  - .nav-tabs--small
  - .tab-content
  - .tab-content.active
  - .table-wrapper
  - .u-ui-padding-bottom-small
  - .u-ui-padding-bottom-medium
  - .u-ui-padding-bottom-large
  - .u-ui-padding-bottom-x-large
  - .u-ui-padding-bottom-xx-large
  - .u-ui-padding-horizontal-large
  - .u-ui-padding-horizontal-x-large
  - .u-ui-padding-top-small
  - .u-ui-padding-top-medium
  - .u-ui-padding-top-x-large
  - .u-ui-padding-top-xx-large
 */

$nav-header-size: 57px;

.faq-content {
  @include type(type('body'));

  .is-webview.browser-android & {
    color: palette(greyscale-level-2);
  }

  &.in {
    padding: 0 0 $half-gutter;
    margin-bottom: ($gutter * 2);
    border-bottom: $box-line;
  }

  .tab-content {
    min-height: initial;
  }

  strong {
    @include type(type('title'));
  }

  p + p {
    margin-top: 0;
    margin-bottom: unit(3);
  }

  p {
    margin-top: unit(6);
    margin-bottom: unit(3);
  }

  ul,
  ol {
    padding-left: 0;
  }

  ul ul,
  ol ol {
    padding-left: unit(6);
  }

  li:not(.nav-item) {
    margin-top: 0;

    content: counter(list-counter);

    & + li {
      margin-top: unit(3);
    }

    & > * {
      margin-top: unit(3);
    }
  }

  ul:not(.nav-tabs),
  ol {
    margin: unit(3) 0;

    &:not(.nav-tabs):first-child {
      padding: unit(3) 0;
    }
  }

  ol {
    list-style-type: none;

    & > :first-child {
      counter-reset: item;
    }

    & > li {
      display: table;
      margin-left: 0;
    }

    & > li::before {
      display: table-cell;
      padding-right: unit(2);
      content: counters(item, '.') '. ';
      counter-increment: item;
    }

    li {
      ol > li {
        margin: 0;
      }

      ol > li::before {
        content: counters(item, '.') ' ';
      }
    }
  }

  // Fixes the block jumping when opening and closing the dialog
  & > :first-child {
    .is-webview & {
      padding-top: 0;
    }
  }

  h1 {
    @include font-size($font-size-big);
    font-weight: $font-weight-bold;
    line-height: $headings-line-height;
    color: palette(greyscale-level-1);
  }

  h2 {
    @include type(type('title'));
    margin: 0 0 unit(2);
  }

  table {
    margin: unit(4) 0;
    border-collapse: collapse;
  }

  th {
    @include type(type('title'));
    padding: unit(2);
    text-align: left;
    background: $user-created-content-table-header-bg;
    border: $user-created-table-border-style;
  }

  td {
    @include type(type('body'));
    padding: unit(2);
    border: $user-created-table-border-style;
  }

  .nav-tabs {
    margin-bottom: unit(8);
  }

  :target {
    display: inline-block;
    padding-top: $nav-header-size;
    margin-top: -$nav-header-size;
  }

  blockquote {
    margin-left: 0;
  }

  .important-note {
    border-left: unit(0.5) solid palette(expose-default);
  }

  .default-note {
    border-left: unit(0.5) solid palette(greyscale-level-5);
  }

  // Fix scroll below margin caused by Google Translate extensions in help center entries
  #gtx-trans {
    display: none;
  }
}

.faq-content--accordion {
  details[open] > summary:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGZpbGw9IiM5OTk5OTkiIGQ9Ik0xOC4wMDIgMTQuNDVsLTYuMDAxLTUuOTQ5TDYgMTQuNDUxbDEuMDYgMS4wNSA0Ljk0MS00Ljg5NyA0Ljk0IDQuODk3eiIvPgo8L3N2Zz4K);
  }

  summary {
    @include type(type('title'));

    outline: none;
    padding: $cell-padding;
    color: palette(greyscale-level-1);
    cursor: pointer;

    &::-webkit-details-marker,
    &::marker {
      display: none;
      content: "";
    }

    &::after {
      content: "";
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGZpbGw9IiM5OTk5OTkiIGQ9Ik0xOC4wMDIgOS41NTJsLTEuMDYxLTEuMDUxLTQuOTQgNC44OTdMNy4wNiA4LjUwMSA2IDkuNTUybDYuMDAxIDUuOTQ5eiIvPgo8L3N2Zz4K);
      width: unit(6);
      height: unit(6);
      float: right;
    }


    &:hover {
      background: $cell-navigating-hover-background;
    }
  }
}

.c-text {
  @include font-smoothing;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.c-text--heading {
  @include type(type('heading'));
}
