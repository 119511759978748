@use "sass:math";

@mixin clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin arrow($direction, $color, $size) {
  height: 0;
  width: 0;

  @if $direction == 'top' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  } @else if $direction == 'right' {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if $direction == 'bottom' {
    border-top: $size solid $color;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
  } @else if $direction == 'left' {
    border-top: $size solid transparent;
    border-right: $size solid $color;
    border-bottom: $size solid transparent;
  } @else if $direction == 'top-left' {
    border-top: $size solid $color;
    border-right: $size solid transparent;
  } @else if $direction == 'top-right' {
    border-top: $size solid $color;
    border-left: $size solid transparent;
  } @else if $direction == 'bottom-left' {
    border-bottom: $size solid $color;
    border-right: $size solid transparent;
  } @else if $direction == 'bottom-right' {
    border-bottom: $size solid $color;
    border-left: $size solid transparent;
  }
}

@mixin right-arrow($size: normal, $color: palette(greyscale-level-5)) {
  &:after {
    @include content('inline-block');
    transform: rotate(45deg);
    position: absolute;
    vertical-align: bottom;
    border: solid 2px $color;
    border-bottom: 0;
    border-left: 0;

    @if $size == 'normal' {
      top: calc(50% - #{$quarter-gutter});
      right: ($gutter - 2px); // center arrow in container
      width: $half-gutter;
      height: $half-gutter;
    }
    @else if $size == 'small' {
      top: calc(50% - #{$quarter-gutter * 0.5});
      right: ($half-gutter); // center arrow in container
      width: $quarter-gutter;
      height: $quarter-gutter;
      border: solid 1px $color;
      border-bottom: 0;
      border-left: 0;
    }
  }
}

@mixin content($display: false, $content: false) {
  @if $display == 'inline-block' {
    position: relative;
    display: inline-block;
  }
  @else {
    position: absolute;
    display: block;
  }

  @if $content {
    content: $content;
  }
  @else {
    content: '';
  }
}

@mixin fully-rounded() {
  border-radius: 9999px !important; // perfect circle on any size
}

@mixin center($type: both, $position: relative, $important: false) {
  $force: '';

  @if $important {
    $force: '!important';
  }

  @if $position == absolute {
    position: absolute unquote($force);
    margin: auto;

    @if $type == horizontal or $type == both {
      right: 0;
      left: 0;
    }

    @if $type == vertical or $type == both {
      top: 0;
      bottom: 0;
    }
  }
  @else {
    position: relative unquote($force);

    @if $type == vertical {
      transform: translateY(-50%);
      top: 50%;
    }

    @if $type == horizontal {
      transform: translateX(-50%);
      left: 50%;
    }

    @if $type == both {
      transform: translateX(-50%) translateY(-50%);
      top: 50%;
      left: 50%;
    }
  }
}

@mixin size($size: auto) {
  width: $size;
  height: $size;
}

// Used for cases, when you need to hide something, but preserve
// container's dimensions etc. (some scrips may require fixed parent height, e.g. maps container)
@mixin invisible($important: false) {
  position: absolute !important;
  top: -9999em;
  right: 0;
  left: 0;
}

@mixin site-container {
  position: relative;
  width: $page-width;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  .is-wide & {
    width: $page-width-wide;
    padding-right: $gutter;
    padding-left: $gutter;

    @include respond-to(tablets) {
      margin: 0;
    }

    @include respond-to(phones) {
      padding-right: map-get($grid-gutters, phones);
      padding-left: map-get($grid-gutters, phones);
    }
  }
}

@mixin device-modifiers {
  @content;

  &\@phones {
    @include respond-to(phones) {
      @content;
    }
  }

  &\@tablets {
    @include respond-to(tablets) {
      @content;
    }
  }

  &\@tablets-up {
    @include respond-to(tablets-up) {
      @content;
    }
  }

  &\@portables {
    @include respond-to(portables) {
      @content;
    }
  }

  &\@desktops {
    @include respond-to(desktops) {
      @content;
    }
  }

  &\@webview {
    .is-webview & {
      @content;
    }
  }
}

@mixin sticky {
  position: fixed;

  @supports (position: sticky) {
    position: sticky;
  }

  @supports (position: -webkit-sticky) {
    position: -webkit-sticky;
  }
}

@mixin sticky-container($fallback-offset: 0) {
  padding: $fallback-offset;

  @supports ((position: sticky) or (position: -webkit-sticky)) {
    padding: 0 !important;
  }
}

// Quote from https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-overflow-scrolling
// "Do not use it on production sites facing the Web: it will not work for every user."
// It should be avoided and, ideally, removed from the codebase once we drop support for iOS 12
// Reference: https://developer.apple.com/documentation/safari-release-notes/safari-13-release-notes
@mixin ios-smooth-scroll {
  -webkit-overflow-scrolling: touch;
}

@mixin hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin stretch($offset-top: 0, $offset-right: 0, $offset-bottom: 0, $offset-left: 0) {
  position: absolute;

  @if $offset-top {
    top: $offset-top;
  }
  @if $offset-bottom {
    bottom: $offset-bottom;
  }
  @if $offset-left {
    left: $offset-left;
  }
  @if $offset-right {
    right: $offset-right;
  }
}

@mixin ellipsis($no-wrap: true) {
  overflow: hidden;
  text-overflow: ellipsis;

  @if $no-wrap {
    white-space: nowrap;
  }
}

@mixin force-wrap {
  white-space: pre;
  // CSS 2.0
  white-space: pre-wrap;
  // CSS 2.1
  white-space: pre-line;
  // CSS 3.0
  white-space: -pre-wrap;
  // Opera 4-6
  white-space: -o-pre-wrap;
  // Opera 7
  white-space: -moz-pre-wrap;
  // Mozilla
  white-space: -hp-pre-wrap;
  // HP Printers
  word-wrap: break-word;
  // IE 5+
}

@mixin user-select($value) {
  -ms-user-select: $value;
  -moz-user-select: $value;
  -webkit-user-select: $value;
  user-select: $value;
}

@mixin visually-hidden() {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
}
