.label-type-selection-modal__body {
  display: flex;
  min-height: $label-type-selection-modal-min-height;
  flex-direction: column;

  @include respond-to(phones) {
    min-height: $label-type-selection-modal-phones-min-height;
  }
}

