.pro-integrations {
  @include pro-integrations-section-layout(center);
  @include pro-integrations-section-h-gutter;
  box-sizing: border-box;
  overflow-x: hidden;
}

// Introduction section
.pro-integrations__introduction {
  @include pro-integrations-section-layout(flex-start);
  @include pro-integrations-section-padding-large(top);
  max-width: $pro-integrations-layout-max-width-narrow;
  text-align: left;

  @include respond-to(tablets-up) {
    @include pro-integrations-section-layout(center);
    text-align: center;
  }
}

.pro-integrations__introduction-title {
  @include pro-integrations-title;
}

.pro-integrations__introduction-description {
  @include pro-integrations-body;
  @include pro-integrations-section-padding(bottom);
  @include pro-integrations-section-padding(top);
}

.pro-integrations__introduction-button {
  @include pro-integrations-button;
}

.pro-integrations__introduction-notice {
  @include pro-integrations-body-small;
  @include pro-integrations-section-padding(top);
}

.pro-integrations__introduction-image {
  @include pro-integrations-section-padding-large(top);
  width: 100vw;

  img {
    min-height: unit(75);

    @include respond-to(phones) {
      object-position: 70% 50%;
    }
  }
}

// Value proposition section
.pro-integrations__value-proposition {
  @include pro-integrations-section-layout(center);
  @include pro-integrations-section-padding-large(top);
  max-width: $pro-integrations-layout-max-width-regular;
  position: relative;
  overflow-x: hidden;
}

.pro-integrations__value-proposition-title {
  @include pro-integrations-title;

  @include respond-to(tablets-up) {
    text-align: center;
  }
}

.pro-integrations__value-proposition-content {
  @include pro-integrations-section-padding-large(bottom);
  @include pro-integrations-section-padding(top);
  @include pro-integrations-section-h-gutter;
  width: 100vw;
  max-width: $pro-integrations-layout-max-width-regular;
  overflow-x: auto;
  display: grid;
  grid-template-columns: repeat(3, unit(60));
  grid-gap: $pro-integrations-layout-extra-h-gutter;
  scroll-snap-type: x mandatory;
  box-sizing: border-box;
  
  @include respond-to(tablets-up) {
    align-self: center;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $pro-integrations-layout-spacing;
  }
}

.pro-integrations__value-proposition-card {
  width: 100%;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  gap: unit(3);
  padding: unit(5);
  border-radius: unit(5);
  background-color: palette(greyscale-level-5);
  box-sizing: border-box;
  height: 100%;
  scroll-margin-left: $pro-integrations-layout-extra-h-gutter;

  @include respond-to(tablets-up) {
    padding: $pro-integrations-layout-spacing;
  }
}

.pro-integrations__value-proposition-card-image {
  width: 100%;
  max-width: unit(50);
  align-self: center;
  padding-bottom: unit(6);
  margin-top: unit(-10);

  @include respond-to(tablets-up) {
    margin-top: unit(-13);
  }
}

.pro-integrations__value-proposition-card-title {
  @include pro-integrations-subtitle;
}

.pro-integrations__value-proposition-card-description {
  @include pro-integrations-body-small;
}

// Application section
.pro-integrations__application {
  @include pro-integrations-section-layout;
  @include pro-integrations-section-padding-large(top);
  @include pro-integrations-section-padding-large(bottom);
  width: 100vw;
  background-color: palette-light(br20);

  @include respond-to(desktops) {
    @include pro-integrations-section-layout(center);
    width: 100vw;
  }

  .pro-integrations__application-title {
    @include pro-integrations-title;
    @include pro-integrations-section-h-gutter;
  }

  .pro-integrations__application-inner {
    @include pro-integrations-section-h-gutter;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: $pro-integrations-layout-spacing;
    gap: $pro-integrations-layout-spacing;

    @include respond-to(desktops) {
      max-width: $pro-integrations-layout-max-width-regular;
      flex-direction: row;
      justify-content: space-between;
      padding-top: $pro-integrations-layout-spacing-large;
    }
  }

  .pro-integrations__application-image {
    @include pro-integrations-media-border-radius;
    flex: 1;
    max-width: unit(120);
  }

  .pro-integrations__application-requirements {
    flex: 1;
    max-width: none;

    @include respond-to(desktops) {
      max-width: unit(120);
    }
  }

  .pro-integrations__application-requirements-heading {
    @include pro-integrations-subtitle;
  }

  .pro-integrations__application-requirements-body {
    @include pro-integrations-body-small;
    white-space: pre-wrap;
    padding-top: $pro-integrations-layout-spacing;
  }
}

// Documentation section
.pro-integrations__documentation {
  @include pro-integrations-section-layout(center);
  @include pro-integrations-section-padding-large(top);
  @include pro-integrations-section-padding-large(bottom);
  width: 100vw;
  background-color: palette(primary-dark);
  color: palette(greyscale-level-6);
}

.pro-integrations__documentation-inner {
  @include pro-integrations-section-h-gutter;
  box-sizing: border-box;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  width: 100%;
  gap: $pro-integrations-layout-spacing;

  @include respond-to(desktops) {
    max-width: $pro-integrations-layout-max-width-regular;
    flex-direction: row;
    justify-content: space-between;
  }
}

.pro-integrations_documentation-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  max-width: none;

  @include respond-to(desktops) {
    max-width: unit(120);
  }
}

.pro-integrations__documentation-image {
  display: none;
  width: 100%;
  height: 100%;
  max-width: unit(120);
  flex: 1;

  @include respond-to(desktops) {
    display: block;
  }
}

.pro-integrations__documentation-title {
  @include pro-integrations-title;
  color: palette(greyscale-level-6);
}

.pro-integrations__documentation-description {
  @include pro-integrations-body;
  color: palette(greyscale-level-6);
  text-align: left;
  padding: $pro-integrations-layout-spacing 0;

  @include respond-to(tablets-up) {
    padding: $pro-integrations-layout-spacing-large 0;
  }
}

.pro-integrations__documentation-button {
  @include pro-integrations-button;
}

// Accordion section
.pro-integrations__accordion {
  @include pro-integrations-section-layout;
  @include pro-integrations-section-padding-large(top);
  @include pro-integrations-section-padding-large(bottom);
  box-sizing: border-box;
  max-width: $pro-integrations-layout-max-width-regular;
  position: relative;
  overflow-x: hidden;

  @include respond-to(tablets-up) {
    padding-left: $pro-integrations-layout-extra-h-gutter-desktop;
    padding-right: $pro-integrations-layout-extra-h-gutter-desktop;
  }
}

.pro-integrations__accordion-title {
  @include pro-integrations-title;
}

.pro-integrations__accordion-group {
  @include pro-integrations-section-padding(top);
  display: flex;
  flex-flow: column;
  width: 100%;

  @include respond-to(desktops) {
    flex-flow: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.pro-integrations__accordion-group-item {
  width: 100%;
  border-bottom: 1px solid palette(greyscale-level-4);

  #accordion_pro-integration-header {
    background: transparent;
    padding: unit(4);

    @include respond-to(desktops) {
      padding: unit(6);
    }

    .web_ui__Cell__navigating:hover,
    .web_ui__Cell__navigating:active {
      background: transparent;
    }
    .web_ui__Cell__title {
      @include pro-integrations-subtitle;
      padding:  unit(0);
    }
  }

  #accordion_pro-integration-body  {
    background-color: transparent;
    padding: unit(0) unit(4) unit(4) unit(4);

    @include respond-to(desktops) {
      padding: unit(0) unit(6) unit(6) unit(6);
    }

    .web_ui__Cell__body {
      @include pro-integrations-body-small;
    }

    .web_ui__Accordion__regular {
      padding: unit(0);
    }
  }

}

.pro-integrations__accordion-group-item:last-child {
  border-bottom: none;
}

// Contact section
.pro-integrations__contact {
  @include pro-integrations-section-layout(center);
  @include pro-integrations-section-padding-large(top);
  @include pro-integrations-section-padding-large(bottom);
  @include pro-integrations-section-h-gutter;
  box-sizing: border-box;
  width: 100vw;
  background-color: palette(primary-default);

}

.pro-integrations__contact-title {
  @include pro-integrations-title;
  text-align: center;
  color: palette(greyscale-level-6);
}

.pro-integrations__contact-description {
  @include pro-integrations-section-padding-large(top);
  @include pro-integrations-body;
  text-align: center;
  color: palette(greyscale-level-6);
}

.pro-integrations__contact-button {
  @include pro-integrations-section-padding-large(top);
  @include pro-integrations-section-padding-large(bottom);
  @include pro-integrations-button;
}

// Footer section
.pro-integrations__footer {
  width: 100vw;
}

// Application from header section
.pro-integrations-application_form-header {
  .pro-integrations-application_form-header-title {
    @include pro-integrations-title;
    @include respond-to(tablets-up) {
      text-align: center;
    }
  }

  .pro-integrations-application_form-header-description {
    @include pro-integrations-body;
    max-width: none;
    margin-top: unit(4);

    @include respond-to(tablets-up) {
    margin-top: unit(12);
    text-align: center;
    }
  }
}

// Application form success section
.pro-integrations-application_form-success {
  .pro-integrations-application_form-success-icon {
    width: unit(12);
    height: unit(12);

    @include respond-to(tablets-up) {
      width: unit(24);
      height: unit(24);
    }
  }

  .pro-integrations-application_form-success-title {
    @include pro-integrations-title;
    margin: unit(4) 0;

    @include respond-to(tablets-up) {
      margin: unit(12) 0;
    }
  }

  .pro-integrations-application_form-success-description {
    @include pro-integrations-body;
    max-width: none;
  }
}