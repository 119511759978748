$intelelctual-property-infringement-accordion-padding: map-get(
  $spacer-sizes,
  x-large
);

.intellectual-property-infringement {
  max-width: $page-width-narrow;
  margin: auto;
}

.intellectual-property-infringement__accordion-suffix {
  padding: $intelelctual-property-infringement-accordion-padding;
  padding-left: 0;
}

.intellectual-property-infringement__accordion-prefix {
  padding: $intelelctual-property-infringement-accordion-padding;
  padding-right: 0;
}

.intellectual-property-infringement__file-attachments {
  display: flex;
  gap: map-get($spacer-sizes, regular);
  flex-wrap: wrap;
}
