$page-content-width: unit(329);
$electronics-bg-color: #edf2f2;
$electronics-bg-color-2: #edffe9;
$electronics-text-color: #000000;
$electronics-text-color-2: #1a4552;
$electronics-cta-color: #327580;

@mixin full-width-section() {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}

@mixin content-container() {
  box-sizing: border-box;
  width: 100%;
  max-width: $page-content-width;
  padding-right: unit(5);
  padding-left: unit(5);
  margin-right: auto;
  margin-left: auto;
}

.electronics__landing-page-container {
  @include content-container;
}

.electronics__introduction-section {
  padding-top: unit(4);
  color: $electronics-text-color;

  @include respond-to(desktops) {
    padding-top: unit(9);
  }
}

.electronics__introduction-headline {
  line-height: unit(7.5);
  font-size: unit(4.5);
  text-align: center;
  font-weight: 375;

  @include respond-to(desktops) {
    font-size: unit(8);
  }
}

.electronics__introduction-title {
  text-align: center;
  max-width: unit(264);
  margin: unit(4.5) auto unit(5.5);
  font-size: unit(13);
  line-height: unit(13.5);
  font-weight: 500;
  letter-spacing: unit(-0.5);

  @include respond-to(desktops) {
    margin-top: unit(12.5);
    margin-bottom: unit(13);
    letter-spacing: unit(-0.75);
    font-size: unit(24);
    line-height: unit(20.5);
  }
}

.electronics__introduction-description {
  text-align: center;
  max-width: unit(210);
  margin: 0 auto;
  font-size: unit(3.5);
  line-height: unit(5.25);
  font-weight: 375;

  @include respond-to(desktops) {
    font-size: unit(5.5);
    line-height: unit(7.5);
  }
}

.electronics__introduction-cta {
  margin: unit(7) auto unit(12.5);
  width: fit-content;

  .web_ui__Button__button {
    border-radius: unit(2);
    padding-left: unit(6.5);
    padding-right: unit(6.5);
    height: unit(10);

    @include respond-to(desktops) {
      margin-bottom: unit(23.5);
      margin-top: unit(10.5);
      height: unit(12.5);
      padding-left: unit(11);
      padding-right: unit(11);
    }
  }

  .web_ui__Button__filled {
    background-color: $electronics-cta-color;
  }
}

.electronics__introduction-image {
  margin: 0 auto;
  text-align: center;
  position: relative;

  .web_ui__Image__image {
    border-radius: unit(5);
    z-index: 1;
    @include respond-to(portables) {
      max-height: unit(102.5);
      max-width: unit(84);
    }
  }

  &::after {
    @include full-width-section;
    content: "";
    display: block;
    position: absolute;
    height: 50%;
    background: $electronics-bg-color;
    bottom: 0;
  }
}

.electronics__why-section {
  position: relative;
  color: $electronics-text-color;
  padding-top: unit(15.75);
  padding-bottom: unit(14.75);
  z-index: 1;

  @include respond-to(tablets-up) {
    padding-top: unit(30);
    padding-bottom: unit(34.5);
  }

  &::after {
    @include full-width-section;

    content: "";
    display: block;
    position: absolute;
    height: 100%;
    background-color: $electronics-bg-color;
    bottom: 0;
    z-index: -1;
  }
}

.electronics__why-title {
  font-size: unit(7);
  font-weight: 500;
  line-height: unit(8);
  letter-spacing: unit(-0.05);
  text-align: center;
  padding: 0 unit(2) unit(11);

  @include respond-to(tablets-up) {
    padding: 0 0 unit(20.5);
    font-size: unit(12);
    line-height: unit(13);
    letter-spacing: unit(-0.25);
  }
}

.electronics__why-cards-container {
  padding-left: unit(2);
  padding-right: unit(2);
  display: flex;
  flex-direction: column;
  gap: unit(5);
  width: fit-content;
  margin: 0 auto;

  @include respond-to(desktops) {
    padding-left: 0;
    padding-right: 0;
    flex-direction: row;
    gap: unit(8.75);
  }
}

.electronics__why-card {
  width: 100%;
  box-sizing: border-box;
  border-radius: unit(5);
  background: palette(greyscale-level-6);
  display: block;
  padding: unit(8.25) unit(6.75) unit(7);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include respond-to(tablets-up) {
    width: 100%;
    max-width: unit(100.5);
    padding: unit(14) unit(9.5) unit(10);
  }
}

.electronics__why-card-image {
  width: unit(33.75);
  height: unit(33.75);
  margin: 0 auto unit(10.5);

  @include respond-to(tablets-up) {
    width: unit(40);
    height: unit(40);
    margin: 0 auto unit(19.25);
  }
}

.electronics__why-card-title {
  margin: 0;
  font-size: unit(6);
  font-weight: 500;
  line-height: unit(6.5);
  text-align: left;
}

.electronics__why-card-description {
  margin-top: 0;
  font-size: unit(4);
  font-weight: 375;
  line-height: unit(6);
  text-align: left;
  padding-top: unit(6.25);
  padding-bottom: unit(12);

  @include respond-to(tablets-up) {
    padding-top: unit(8);
    padding-bottom: unit(19.75);
  }
}

.electronics__why-card-link {
  align-items: center;
  font-size: unit(4);
  font-weight: 500;
  line-height: unit(6);
  text-align: left;
  text-decoration: none !important;
  display: flex;
  gap: unit(3);

  @include respond-to(tablets-up) {
    gap: unit(4);
  }
}

.electronics__why-card-link-icon {
  height: unit(5);
  width: unit(5);
}

.electronics__how-section {
  display: block;
  color: $electronics-text-color;
  padding-top: unit(15.5);
  padding-bottom: unit(30);

  @include respond-to(desktops) {
    position: relative;
    display: flex;
    justify-content: flex-start;
    padding-top: unit(29);
    padding-bottom: unit(34.4);
    gap: unit(42.5);
  }
}

.electronics__how-title {
  font-size: unit(7);
  font-weight: 500;
  line-height: unit(8);
  letter-spacing: unit(-0.05);
  text-align: center;
  padding-bottom: unit(16.5);

  @include respond-to(desktops) {
    position: sticky;
    top: unit(37.5);
    height: fit-content;
    margin-bottom: unit(64.5);
    text-align: left;
    max-width: unit(64.75);
    padding-bottom: 0;
    font-size: unit(12);
    line-height: unit(13);
    letter-spacing: unit(-0.25);
  }
}

.electronics__how-cards-container {
  display: flex;
  flex-direction: column;
  gap: unit(21.25);
  width: fit-content;
  margin: 0 auto;

  @include respond-to(desktops) {
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    gap: unit(33.25);
    margin: 0;
  }
}

.electronics__how-card {
  width: 100%;
  display: block;

  @include respond-to(tablets-up) {
    width: fit-content;
    display: flex;
    gap: unit(13.5);
  }
}

.electronics__how-card-image {
  width: unit(62.5);
  height: unit(76.75);
  border-radius: unit(5);
  margin: 0 auto unit(6.75);
  overflow: hidden;
  aspect-ratio: calc(unit(62.5) / unit(76.75));

  @include respond-to(desktops) {
    width: unit(73.25);
    height: unit(90);
    aspect-ratio: calc(unit(73.25) / unit(90));
    border-radius: unit(5);
    margin: 0 auto 0;
  }
}

.electronics__how-card:nth-child(odd) {
  .electronics__how-card-image {
    transform: rotate(2.92deg);
  }
}

.electronics__how-card:nth-child(even) {
  .electronics__how-card-image {
    transform: rotate(-2.8deg);
  }
}

.electronics__how-card-title {
  display: flex;
  font-size: unit(5.5);
  font-weight: 500;
  line-height: unit(6.5);
  letter-spacing: unit(-0.05);
  text-align: left;

  @include respond-to(desktops) {
    font-size: unit(8);
    line-height: unit(8.5);
    letter-spacing: unit(-0.25);
    padding-top: unit(14);
  }
}

.electronics__how-card-title-number {
  display: block;
  width: unit(7.5);
  margin-right: unit(1.25);

  @include respond-to(tablets-up) {
    width: unit(7.5);
    margin-right: unit(3.5);
  }
}

.electronics__how-card-title-text {
  max-width: unit(60.25);

  @include respond-to(tablets-up) {
    max-width: unit(87.5);
  }
}

.electronics__how-card-description {
  max-width: unit(64);
  margin-top: 0;
  font-size: unit(3.5);
  font-weight: 375;
  line-height: unit(5.25);
  text-align: left;
  padding-top: unit(3);
  padding-left: unit(8.75);

  @include respond-to(tablets-up) {
    max-width: unit(89);
    padding-top: unit(8);
    padding-bottom: unit(19.75);
    padding-left: unit(11);
    font-size: unit(4);
    line-height: unit(6);
  }
}

.electronics__discover-section {
  @include full-width-section;

  background: $electronics-bg-color-2;
  padding: unit(13.75) 0;
  color: $electronics-text-color-2;

  a {
    color: $electronics-text-color-2;
    text-decoration: none;
  }

  @include respond-to(tablets-up) {
    padding: unit(26) 0 unit(21);
  }
}

.electronics__discover-section-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @include respond-to(desktops) {
    flex-wrap: wrap;
    max-width: $page-content-width;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 auto;
  }
}

.electronics__discover-title {
  font-size: unit(7);
  font-weight: 500;
  line-height: unit(8);
  letter-spacing: unit(-0.05);
  text-align: left;
  padding: 0 unit(5) unit(11.5);
  order: 1;

  @include respond-to(desktops) {
    box-sizing: content-box;
    flex-grow: 0;
    height: fit-content;
    font-size: unit(12);
    line-height: unit(13);
    letter-spacing: unit(-0.25);
    padding: 0 0 unit(19) unit(5);
  }
}

.electronics__discover-link {
  padding: unit(11.5) unit(5) 0;
  order: 3;
  font-size: unit(3.5);
  font-weight: 500;
  line-height: unit(6);
  text-align: left;
  display: flex;
  align-items: center;

  @include respond-to(desktops) {
    box-sizing: content-box;
    padding: unit(5) unit(5) 0;
    flex-grow: 0;
    order: 2;
    height: fit-content;
    font-size: unit(4);
    text-align: left;
  }
}

.electronics__discover-link-icon {
  padding-left: unit(2);
  height: unit(4);
  width: unit(4);

  @include respond-to(tablets-up) {
    height: unit(5);
    width: unit(5);
  }
}

.electronics__discover-cards-container {
  order: 2;
  padding: 0 unit(2.5);
  display: flex;
  flex-wrap: wrap;
  row-gap: unit(8);

  @include respond-to(desktops) {
    box-sizing: border-box;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;
    max-width: $page-content-width;
    order: 3;
    flex-wrap: nowrap;
    padding: 0 unit(5);
    gap: unit(2.5);
  }
}

.electronics__discover-card {
  width: 50%;
  box-sizing: border-box;
  padding: 0 unit(2.5);

  @include respond-to(desktops) {
    width: 100%;
    padding: 0;
    max-width: unit(46);
    flex-wrap: nowrap;
    border-radius: unit(5);
  }
}

.electronics__discover-card-image {
  width: 100%;
  aspect-ratio: calc(unit(157) / unit(202));
  border-radius: unit(3.75);
  overflow: hidden;

  @include respond-to(desktops) {
    max-width: unit(45.75);
    max-height: unit(59);
    border-radius: unit(5);
  }
}

.electronics__discover-card-title {
  margin-top: unit(1.5);
  width: 95%;
  font-size: unit(3.5);
  font-weight: 375;
  line-height: unit(3.75);
  text-align: left;

  @include respond-to(desktops) {
    margin-top: unit(2.5);
    font-size: unit(4.5);
    line-height: unit(5);
    text-align: ceunit(12);
  }
}

.electronics__bottom-section {
  position: relative;
  padding-top: unit(12.5);
  padding-bottom: unit(11.25);

  @include respond-to(desktops) {
    padding-top: unit(21.25);
    padding-bottom: unit(15);
  }

  &::after {
    @include full-width-section;

    content: "";
    display: block;
    position: absolute;
    height: 100%;
    background: $electronics-text-color-2;
    bottom: 0;
    z-index: 0;
  }

  a {
    text-decoration: none;
  }
}

.electronics__bottom-title {
  position: relative;
  z-index: 1;
  font-size: unit(10);
  font-weight: 500;
  line-height: unit(11);
  letter-spacing: unit(-0.25);
  text-align: center;
  color: $electronics-bg-color-2;
  padding-bottom: unit(7.75);

  @include respond-to(desktops) {
    font-size: unit(19);
    line-height: unit(20.5);
    letter-spacing: unit(-0.5);
    text-align: center;
    padding-bottom: unit(8.5);
  }
}

.electronics__bottom-description {
  position: relative;
  z-index: 1;
  color: $electronics-bg-color-2;
  font-size: unit(3.5);
  font-weight: 375;
  line-height: unit(5.25);
  text-align: center;
  margin: 0 auto;

  @include respond-to(desktops) {
    max-width: unit(212.5);
    font-size: unit(5.5);
    line-height: unit(7.5);
    text-align: center;
  }
}

.electronics__bottom-cta {
  margin: 0 auto;
  padding-top: unit(8.75);
  padding-bottom: unit(5.5);
  position: relative;
  z-index: 1;
  width: fit-content;

  .web_ui__Button__button {
    border-radius: unit(2);
    padding-left: unit(6.5);
    padding-right: unit(6.5);
    height: unit(10);

    @include respond-to(desktops) {
      height: unit(12.5);
      padding-left: unit(11);
      padding-right: unit(11);
    }
  }
  
  .web_ui__Button__filled {
    background-color: $electronics-bg-color-2;
  }

  .web_ui__Button__content {
    color: $electronics-text-color-2;
  }

  @include respond-to(desktops) {
    width: fit-content;
    padding-top: unit(14.5);
    padding-bottom: unit(6.25);
  }
}

.electronics__bottom-link {
    position: relative;
    z-index: 1;
    text-align: center;

  a {
    color: $electronics-bg-color-2;
    font-size: unit(3.5);
    font-weight: 375;
    line-height: unit(5.5);
    

    @include respond-to(desktops) {
      font-size: unit(4);
      line-height: unit(5.5);
    }
  }
}

.electronics__disclaimer-section {
  position: relative;
  padding-top: unit(3);
  padding-bottom: unit(4.75);

  &::after {
    @include full-width-section;

    content: "";
    display: block;
    position: absolute;
    height: 100%;
    background: palette(greyscale-level-6);
    bottom: 0;
    z-index: 0;
  }

  @include respond-to(desktops) {
    padding-top: unit(5);
    padding-bottom: unit(15.5);
  }
}

.electronics__disclaimer-text {
  color: palette(greyscale-level-2);
  z-index: 1;
  position: relative;
  font-size: unit(2.5);
  font-weight: 375;
  line-height: unit(5.5);
  text-align: center;

  @include respond-to(desktops) {
    font-size: unit(3);
    line-height: unit(7.5);
    text-align: center;
  }
}
