@import "../../variables/pages/pro-integrations";

@mixin pro-integrations-title {
  font-size: unit(8);
  font-weight: 580;
  line-height: unit(12);
  color: palette(greyscale-level-1);
  margin: 0;

  @include respond-to(tablets-up) {
    font-size: unit(10.5);
    line-height: unit(13);
  }
}

@mixin pro-integrations-body {
  font-size: unit(5);
  line-height: unit(7);
  font-weight: 375;
  max-width: $pro-integrations-layout-max-width-narrow;
  color: palette(greyscale-level-1);
  margin: 0;

  @include respond-to(tablets-up) {
    font-size: unit(5.5);
    line-height: unit(7.5);
  }
}

@mixin pro-integrations-subtitle {
  @include pro-integrations-body;
  font-weight: 500;
}

@mixin pro-integrations-body-small {
  font-size: unit(4);
  line-height: unit(6.5);
  color: palette(greyscale-level-1);
  margin: 0;
}

@mixin pro-integrations-media-border-radius {
  border-radius: unit(3);
  overflow: hidden;

  @include respond-to(tablets-up) {
    border-radius: unit(5);
  }
}

@mixin pro-integrations-section-layout($align: flex-start, $width: 100%) {
  display: flex;
  flex-direction: column;
  width: $width;
  align-items: $align;
}

@mixin pro-integrations-section-padding-large($direction) {
  padding-#{$direction}: $pro-integrations-layout-spacing-large;

  @include respond-to(tablets-up) {
    padding-#{$direction}: $pro-integrations-layout-spacing-large-desktop;
  }
}

@mixin pro-integrations-section-padding($direction) {
  padding-#{$direction}: $pro-integrations-layout-spacing;

  @include respond-to(tablets-up) {
    padding-#{$direction}: $pro-integrations-layout-spacing-desktop;
  }
}

@mixin pro-integrations-section-h-gutter {
  padding-left: $pro-integrations-layout-extra-h-gutter;
  padding-right: $pro-integrations-layout-extra-h-gutter;

  @include respond-to(tablets-up) {
    padding-left: $pro-integrations-layout-extra-h-gutter-desktop;
    padding-right: $pro-integrations-layout-extra-h-gutter-desktop;
  }
}

@mixin pro-integrations-button {
  min-width: unit(60);
  width: 100%;
  
  @include respond-to(desktops) {
    width: auto;
  }
}