// Some inputs require to be capitalized, make sure placeholder is not
::-webkit-input-placeholder { text-transform: none; }
::-moz-placeholder          { text-transform: none; }
:-ms-input-placeholder      { text-transform: none; }
input:-moz-placeholder      { text-transform: none; }

html {
  height: 100%;
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
button,
textarea {
  font-family: $default-font-family;
  color: palette(greyscale-level-2);
}

body {
  @include sticky-container(90px 0 0 0);
  min-width: $page-width;
  padding-top: $header-height;
  margin: 0;
  display: flex;
  flex-direction: column;

  // always display main scrollbar to prevent page moving
  overflow: -moz-scrollbars-vertical;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 100%;
  position: relative;

  font-size: $font-size-default;
  line-height: $line-height-medium;
  background: RGB($color-background-site);
  -webkit-tap-highlight-color: transparent;

  // Used for detecting breakpoint in JS
  &:after {
    position: absolute;
    font-size: 0; // make sure there's no space after footer
    visibility: hidden;

    @include respond-to(phones) { content: 'phones'; }
    @include respond-to(tablets) { content: 'tablet'; }
    @include respond-to(desktops) { content: 'desktop'; }
  }

  // For pages with wide resolution support
  &.is-wide {
    min-width: $page-width-wide;

    @include respond-to(desktops) {
      // Overwrite possible styles from fancy ads (reskin etc.)
      width: 100% !important;
      max-width: 100% !important;
      min-width: $min-device-width !important;
    }
  }

  @include respond-to(portables) {
    min-width: $min-device-width !important;
    // Overwrite possible styles from fancy ads (reskin etc.)
    max-width: 100% !important;
    width: 100% !important;
  }

  @include respond-to(phones) {
    .site-content .container > .row > .grid4 {
      .box:first-of-type {
        margin-top: $gutter;
      }
    }
  }

  .empty-layout {
    height: 100%;
    min-width: 0;
    padding-top: 0;
    background: $color-background-white;
  }

  // fancy images preload :-)
  &:before {
    // hide pseudo element
    position: absolute;
    top: -9999px;
    left: -9999px;
    width: 1px;
    height: 1px;
  }
}

// Hide generated iframes (pinterest etc.) as they add unwanted gap at the bottom
body > iframe {
  display: none;
}

// Ondato integration requires for the iframe to be shown
body > iframe[src^='https://sandbox-idv.ondato.com/'],
body > iframe[src^='https://idv.ondato.com/'] {
  display: block;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  margin: $half-gutter 0 0 $gutter;
}

table {
  border-spacing: 0;
  width: 100%;
}

img {
  border: 0;
}

// TODO: make this default for all images;
p img {
  max-width: 100%;
  height: auto !important;
}

figure {
  margin: 0;
}

hr {
  border: none;
  border-bottom: 1px solid $color-horizontal-line-default;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
