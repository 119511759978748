$vchoney-semibold-fontfamily: VCHoneySemiBoldDeck, 'Helvetica Neue', 'Helvetica-Neue', 'Arial', sans-serif;
$page-content-width: unit(329);
$hov-color-1: #000000;

@mixin full-width-section() {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}

@mixin content-container() {
  box-sizing: border-box;
  width: 100%;
  max-width: $page-content-width;
  padding-right: unit(5);
  padding-left: unit(5);
  margin-right: auto;
  margin-left: auto;
}

.hov__landing-page-container {
  @include content-container;
}

.hov__logo {
  box-sizing: border-box;
  margin: 0 auto;
  padding: unit(17.25) 0 unit(22);
  max-width: unit(64);

  @include respond-to(desktops) {
    max-width: unit(119.5);
    padding: unit(30) 0;
  }
}

.hov__brandnew-section { 
  color: $hov-color-1 
}

.hov__brandnew-section-title {
  font-family: $vchoney-semibold-fontfamily;
  font-weight: 600;
  font-size: unit(8);
  line-height: unit(9);
  text-align: center;
  margin-bottom: unit(10.5);

  @include respond-to(desktops) {
    font-size: unit(12);
    line-height: unit(10.5);
    margin-bottom: unit(18);
  }
}

.hov__brandnew-section-cards {
  display: flex;
  flex-direction: column;
  gap: unit(13.5);

  @include respond-to(tablets-up) {
    flex-direction: row;
    margin-bottom: unit(5);
    gap: unit(8.75);
  }
}

.hov__brandnew-section-card-image {
  overflow: hidden;
  width: 100%;
  border-radius: unit(5);
  aspect-ratio: 0.99;
  margin-bottom: unit(4);

  @include respond-to(tablets-up) {
    aspect-ratio: 0.81;
    margin-bottom: unit(5);
  }
}

.hov__brandnew-section-card-description {
  margin-top: 0;
  font-weight: 375;
  font-size: unit(3.5);
  line-height: unit(5);
  text-align: center;
  width: 100%;

  @include respond-to(desktops) {
    font-size: unit(4);
    line-height: unit(5.5);
    text-align: left;
    width: 84%;
  }
}
