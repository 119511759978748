@use "sass:math";

$feed-grid-item-vertical-distance: unit(4);
$feed-grid-item-vertical-distance-mobile: unit(2);
$feed-grid-item-horizontal-distance: unit(2);

.feed-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 (-$feed-grid-item-horizontal-distance * 0.5);

  @include respond-to(tablets-up) {
    margin: 0 (-$feed-grid-item-horizontal-distance);
  }
}

.feed-grid__item {
  box-sizing: border-box;
  width: percentage(1*0.5);
  position: relative;

  @include respond-to(tablets-up) {
    width: percentage(math.div(1, 3));
  }

  @include respond-to(wide) {
    width: percentage(1*0.25);
  }
}

.feed-grid__item-content {
  padding: 0 ($feed-grid-item-horizontal-distance * 0.5) $feed-grid-item-vertical-distance-mobile;

  @include respond-to(tablets-up) {
    padding: 0 ($feed-grid-item-horizontal-distance) $feed-grid-item-vertical-distance;
  }
}

.feed-grid__item--full-row {
  width: 100%;
}

.feed-grid__item--one-fifth {
  @include respond-to(tablets) {
    width: percentage(math.div(1, 3));
  }

  @include respond-to(desktops) {
    width: percentage(1*0.25);
  }

  @include respond-to(wide) {
    width: percentage(1*0.2);
  }
}
