.conversation-message {
  @include clearfix;
  word-break: normal;
  @include word-wrap;
  position: relative;
  display: flex;
  flex-direction: column;
  float: left;
  min-height: $chat-bubble-min-height;

  max-width: unit(96);

  @include respond-to(portables) {
    max-width: 70%;
  }
}

.conversation-message__current-author {
  float: right;
}

.conversation-message__suspicious-overlay {
  @include center(vertical, absolute);
  display: flex;
  width: map-get($image-sizes, x2-large);
  overflow: hidden;
  cursor: pointer;
  background: $chat-message-overlay-bg-color;
  border-radius: $input-border-radius;
  align-items: center;
}

.conversation-message__actions {
  width: 100%;
  max-width: unit(68);

  @include respond-to("phones") {
    max-width: 70%;
  }
}

.conversation-message__intro {
  min-width: unit(55);
}

.conversation-message__status {
  margin: 0 -#{unit(4)} unit(5);
}

.conversation-message__photo {
  position: relative;
  margin-bottom: 0;
  transition: margin-bottom 0.2s ease-in-out;
}

.conversation-message__photo--reaction {
  margin-bottom: calc($chat-reaction-badge-size / 2);
}

.conversation-message__bubble {
  position: relative;
  min-width: unit(12);
}

.conversation-message__reaction-badge {
  @include size($chat-reaction-badge-size);

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: calc(-#{$chat-reaction-badge-size} / 2);
  right: unit(1);
  background: $chat-reaction-badge-bg-color;
  border: $chat-reaction-badge-border;
  border-radius: $chat-reaction-badge-border-radius;
}

.conversation-message-container {
  display: flex;
  padding-bottom: unit(1);

  &:hover {
    .message-menu__icon {
      @include respond-to(desktops) {
        display: block;
      }
    }

    .message-menu__content {
      @include respond-to(portables) {
        display: block;
      }
    }
  }
}

.conversation-message-container__grouped {
  margin-left: unit(10);
}

.conversation-message-container__current-author {
  justify-content: flex-end;
}

.conversation-message-menu-container {
  display: flex;
  justify-content: flex-end;
  column-gap: $message-menu-icon-gap;
}

.conversation-message-menu-container--reverse {
  flex-direction: row-reverse;
}
