.container {
  @include clearfix;
  @include site-container;

  @include respond-to(portables) {
    min-width: 0;
    width: auto;
  }

  @include respond-to(tablets) {
    margin-left: $gutter;
    margin-right: $gutter;
  }

  @include respond-to(phones) {
    padding-right: map-get($grid-gutters, phones);
    padding-left: map-get($grid-gutters, phones);
  }

  > .row, .item-information > .row {
    margin-top: $gutter;
    margin-bottom: $gutter;
  }
}

.regular-container {
  max-width: $page-width;
  margin: auto;
}

.site {
  background: RGB($color-background-site);

  // Make sure ads inserted CSS
  // does not affect site on portrait tablets
  @include respond-to(portables) {
    width: 100% !important;
  }

  .u-background-white & {
    background: $color-background-white;
  }
}

// Responsive site blocks

.sidebar-container {
  @extend .grid4;
  @extend %GRID-PORTABLES12;

  @include respond-to(desktops) {
    position: absolute !important;
    top: 0;
    right: 0;
    height: 100%;
    padding-bottom: 70px;
  }

  .item-information & {
    padding-bottom: $half-gutter;

    @include respond-to(portables) {
      padding-bottom: 0;
    }
  }

  .is-wide & {
    @include respond-to(wide) {
      width: 25%;
    }
  }
}

.content-container {
  @extend .grid8;
  @extend %GRID-PORTABLES12;

  .is-wide & {
    @include respond-to(wide) {
      width: 75%;
    }
  }
}

.narrow-container {
  @include respond-to(tablets-up) {
    max-width: 320px;
    margin-right: auto;
    margin-left: auto;
  }
}

.small-container {
  @include respond-to(tablets-up) {
    max-width: 480px;
    margin-right: auto;
    margin-left: auto;
  }
}

.medium-container {
  @include respond-to(tablets-up) {
    max-width: unit(155);
    margin-right: auto;
    margin-left: auto;
  }
}

// Quickfix to fix visual glitches caused by
// absolutely positioned sidebar that overflows
// other sections of our webpage

.content-wrapper-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .content-container, .sidebar-container {
    float: none;
  }

  @include respond-to(desktops) {
    .sidebar-container {
      position: static !important;
      padding-bottom: 0;
      height: auto;
    }
  }
}

