@mixin font-size($size, $important: false) {
  $bang: '';

  @if $important {
    $bang: '!important';
  }

  font-size: $size unquote($bang);
}

@mixin ellipsis($value: ellipsis) {
  $overflow: hidden;

  @if ($value == initial) {
    $overflow: initial;
  }

  overflow: $overflow;
  white-space: nowrap;
  text-overflow: $value;
}

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin font-smoothing($enabled: true) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  @if $enabled == false {
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

@mixin type($properties, $skip-color: false) {
  @each $property, $value in $properties {
    @if $property != 'all-caps' {
      @if $property == 'color' {
        @if $skip-color == false {
          color: #{$value};
        }
      }
      @else {
        #{$property}: #{$value};
      }
    }
    @else {
      text-transform: uppercase;
    }
  }
}

@mixin multiline-ellipsis($lines: 2) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}
