.parcel-measurements-container {
  display: flex;
  flex-wrap: wrap;

  .block {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;

    .web_ui__Cell__cell, .web_ui__Input__input {
      padding-left: 24px;
    }

    @include respond-to(phones) {
      flex: 0 0 100%;

      img {
        max-width: 50%;
      }
    }
  }
}