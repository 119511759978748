.bump-items__content-overlay {
  position: relative;

  .button-overlay {
    @include stretch(0,0, null, null);
    @include fully-rounded;
    z-index: map-get($zindex, overlay);
    background-color: palette(greyscale-level-6);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    margin: 8px;
  }

  .button-overlay--dark {
      background-color: palette(greyscale-level-1);
  }
}

.bump-items__add-button {
  width: unit(32);
  height: unit(32);

  & > * {
    box-sizing: border-box;
    height: 100%;

    & > * {
      height: 100%;
    }
  }
}

.bump-items__add-button-text {
  @include multiline-ellipsis(2);
  white-space: normal;

  & > * {
    @include word-wrap;
  }
}
