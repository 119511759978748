// scss-lint:disable NestingDepth, QualifyingElement, SelectorDepth

.box--item-details {
  margin-top: 0;

  @include respond-to(tablets) {
    display: table;
    box-sizing: border-box;
    width: 100%;
  }

  .details-list__item {
    font-weight: $font-weight-demibold;
    line-height: $line-height-medium;
    color: palette(greyscale-level-2);
  }

  .details-list--main-info {
    @include respond-to(tablets) {
      display: table;
      width: 100%;
    }
  }

  .details-list--pricing {
    @include respond-to(tablets) {
      display: table-cell;
      float: right;
      width: 40%;
    }
  }

  .details-list--details {
    @include font-smoothing;
    margin-top: $half-gutter;
    display: flex;
    flex-direction: column;
    gap: $quarter-gutter;

    @include respond-to(tablets) {
      display: inline-block;
      width: calc(60% - #{$gutter});
      margin-top: 0;
    }

    .details-list__item-value {
      @include word-wrap;
      display: inline-block;
      box-sizing: border-box;
      width: calc(50% - #{$quarter-gutter});
    }

    .details-list__item-value--show-more-button {
      text-transform: none;
      text-align: initial;
      font-weight: 500;
      color: palette(primary-default);

      &:hover {
        text-decoration: underline;
      }
    }

    .details-list__item-value--expandable {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .details-list--actions {
    @include respond-to(tablets) {
      display: inline-block;
      float: right;
      width: 40%;
    }
  }

  .details-list__info {
    padding: 0 $gutter 0;
    margin: $gutter -#{$gutter} 0;
  }

  .details-list--badges {
    @include font-smoothing;
  }
}

.item-page-content-container {
  @extend .grid8;
  @extend %GRID-PORTABLES12;

  .is-wide & {
    @include respond-to(wide) {
      width: 66%;
    }
  }
}

.item-page-sidebar-container {
  @extend .grid4;
  @extend %GRID-PORTABLES12;

  @include respond-to(desktops) {
    position: absolute !important;
    top: 0;
    right: 0;
    height: 100%;
    padding-bottom: 70px;
  }

  .item-information & {
    padding-bottom: $half-gutter;

    @include respond-to(portables) {
      padding-bottom: 0;
    }
  }

  .is-wide & {
    @include respond-to(wide) {
      width: 33%;
    }
  }
}

.item-page-content-container {
  @include respond-to(desktops) {
    padding-left: 0px!important;
    padding-right: unit(7)!important;
  }
}

.item-page-sidebar-container {
  @include respond-to(desktops) {
    padding-left: unit(7)!important;
    padding-right: 0px!important;
  }
}

.item-page-sidebar-content {
  display: flex;
  flex-direction: column;
  gap: unit(4);
}
