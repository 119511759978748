$color-select-icon-size: unit(5.5);
$color-select-border-color: palette(greyscale-level-5);

$color-select-colors-list: (
  'black'     : #000000,
  'brown'     : #663300,
  'purple'    : #800080,
  'green'     : #369a3d,
  'khaki'     : #86814a,
  'blue'      : #007bc4,
  'turquoise' : #b7dee8,
  'red'       : #cc3300,
  'pink'      : #ff0080,
  'rose'      : #ffccca,
  'orange'    : #ffa500,
  'yellow'    : #fff200,
  'grey'      : #919191,
  'body'      : #f4e0c8,
  'white'     : #ffffff,
  'cream'     : #f8f8e1,
  'apricot'   : #ffcc98,
  'coral'     : #ff8f2f,
  'burgundy'  : #ae2e3d,
  'lilac'     : #d297d2,
  'light-blue': #89cff0,
  'navy'      : #35358d,
  'mint'      : #a2ffbc,
  'dark-green': #356639,
  'mustard'   : #e5b539,
  'various'   : url('../../../next/public/assets/default/various.png'),
  'gold'      : url('../../../next/public/assets/default/gold.png'),
  'silver'    : url('../../../next/public/assets/default/silver.png')
);
