// TODO: Rework media queries to use `respond-to`
// after removing css from OneTrust's dashboard.
// Because breakpoints mismatch and it would cause
// weird overwrites here

// TODO: use `unit` helper to define sizes

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy {
  .ot-sdk-cookie-policy-group {
    font-size: 16px !important;
    -webkit-font-smoothing: antialiased;
  }

  .ot-sdk-cookie-policy-group-desc {
    font-size: 16px !important;
    line-height: 22px;
    -webkit-font-smoothing: antialiased;
  }

  table, th, td {
    border: 0 !important;
    border-radius: 0 !important;
  }

  th {
    font-size: 12px !important;
  }

  td {
    border-bottom: 1px solid palette(greyscale-level-6) !important;
  }

  td, .ot-life-span-td-content, .ot-cookies-type-td-content, .ot-cookies-td-content, td a {
    font-size: 12px !important;
    -webkit-font-smoothing: antialiased;
  }

  @media only screen and (min-width: 531px) {
    table tr:last-child td {
      border: 0 !important;
    }
  }

  @media only screen and (max-width: 530px) {
    td:before {
      display: block;
      margin-bottom: 8px;
    }

    tbody tr {
      margin-bottom: 24px !important;
      border: 1px solid palette(greyscale-level-6) !important;
    }

    tbody tr td:last-child {
      border: 0 !important;
    }
  }

  [data-theme='dark'] & {
    table th {
      background-color: palette(greyscale-level-6);
    }

    td {
      border-color: palette(greyscale-level-5) !important;
    }

    a,
    a:hover {
      background-color: transparent;
    }

    h5,
    h6,
    li,
    p,
    a,
    span,
    td,
    th,
    #cookie-policy-description,
    .ot-sdk-cookie-policy-group {
      color: palette(greyscale-level-3);
    }
  }
}
