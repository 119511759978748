.first-time-listing-modal__content {
  height: 100%;
  max-height: calc(100vh - 248px);
  overflow: auto;
}

.first-time-listing-modal__content-separator {
  height: 32px;

  @media only screen and (min-width: 720px) and (max-height: 670px) {
    height: 24px;
  }
}