$input-background-color:                      palette(greyscale-level-6);

$input-note-padding-top:                      unit(1);
$input-title-padding-bottom:                  unit(2);

$input-date-arrow-size:                       unit(3);

$input-value-padding:                         unit(1);
$input-value-suffix-padding:                  unit(5);
$input-value-border-thickness:                unit(0.25);
$input-value-border-color:                    palette(greyscale-level-1, 0.08);
$input-inverse-value-border-color:            palette(greyscale-level-6, 0.24);
$input-value-border:                          $input-value-border-thickness solid $input-value-border-color;

// Text

$input-value-text-type:                       type(user-input);
$input-title-text-type:                       type(subtitle);
$input-note-text-type:                        type(caption);
$input-wide-title-text-type:                  type(title);

$input-value-placeholder-theme:               type(amplified);
$input-value-placeholder-opacity:             0.64;

$input-primary-placeholder-theme:             type(inverse);

// Sizes

$input-padding:                               unit(4);
$input-narrow-padding:                        unit(2);
$input-wide-padding:                          unit(6);
$input-tight-padding:                         unit(0);

// Themes

$input-primary-background-color:              palette(primary-default);
$input-primary-title-theme:                   type(inverse);
$input-primary-value-theme:                   type(inverse);

// States

$input-disabled-opacity-unit:                 0.48;

$input-focused-value-border-color:            palette(primary-default, 0.48);
$input-inverse-focused-value-border-color:    palette(greyscale-level-6, 0.64);
