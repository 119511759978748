$adyen-iframe-spacing: unit(3);
$adyen-large-spinner-size : unit(13) + unit(0.5);
$adyen-spinner-border: unit(1) + unit(0.25); 
$challenge-modal-min-height: unit(50);
$challenge-modal-max-height: 100vh;

.threeds2__card-form-challenge {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: palette(greyscale-level-6);
  z-index: map-get($zindex, bump);
}

.adyen-await {
  height: 100%;

  // Classes that begin with .adyen are Adyen Web Components overrides.
  // Read more - https://docs.adyen.com/online-payments/web-drop-in/customization#css-stylesheet
  .adyen-checkout__spinner__wrapper {
    @include center(both, absolute);
  }

  .adyen-checkout__spinner {
    border: $adyen-spinner-border solid palette(primary-default) !important;
    border-top-color: transparent !important;
  }

  .adyen-checkout__spinner--large {
    width: $adyen-large-spinner-size;
    height: $adyen-large-spinner-size;
  }

  .adyen-checkout__threeds2__challenge {
    background-color: palette(greyscale-level-6);
    z-index: map-get($zindex, bump);
    height: 100%;
  }
}

.threeds2__challenge-modal {
  min-height: $challenge-modal-min-height;
  max-height: $challenge-modal-max-height;
}

// Classes that begin with .adyen are Adyen Web Components overrides.
// .adyen-checkout__await is not mentioned in the documentation, however it is okay to override it.
// https://github.com/Adyen/adyen-web/blob/master/packages/lib/src/components/internal/Await/Await.scss
.adyen-checkout__await {
  border: none !important;
  border-radius: 0px !important;

  .adyen-checkout__spinner__wrapper {
    position: static;
  }
}
