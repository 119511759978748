.business-accounts-navbar {
  a {
    text-decoration: none;
  }

  height: $business-accounts-navbar-height;
}

.business-accounts-navbar__container {
  display: flex;
  height: 100%;
  max-width: $business-accounts-navbar-container-max-width;
  padding: 0 $business-accounts-navbar-container-horizontal-padding;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.business-accounts-navbar__logo {
  img {
    max-height: $business-accounts-navbar-logo-max-height;
  }
}

.business-accounts-navbar__right-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.business-accounts-navbar__logo-suffix {
  display: flex;
  height: unit(8);
  border-left: 1px solid palette(greyscale-level-5);
  align-items: center;
  margin-right: unit(4);
  padding-right: unit(4);
  margin-left: unit(2);
  padding-left: unit(2);
}

.business-accounts-homepage__hero {
  position: relative;
  width: 100%;
  height: $business-accounts-homepage-hero-height-desktops;
  background-color: $business-accounts-homepage-hero-background-color;
  background-image: url("../../../next/public/assets/business-accounts/texture-desktop.png");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;

  @include respond-to(wide) {
    background-size: auto;
  }

  @include respond-to(tablets) {
    height: $business-accounts-homepage-hero-height-tablets;
  }

  @include respond-to(phones) {
    height: $business-accounts-homepage-hero-height-phones;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: $tear-height;
    background: palette(greyscale-level-6);
    -webkit-mask: $tear-horizontal-mask;
    mask: $tear-horizontal-mask;

    @include respond-to(portables) {
      transform: rotateY(180deg);
    }
  }
}

.business-accounts-homepage__hero-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: $business-accounts-homepage-hero-container-max-width;
  margin: 0 auto;
  background-image: url("../../../next/public/assets/business-accounts/hero-desktop.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;

  @include respond-to(portables) {
    background-position: center;
  }
}

.business-accounts-homepage__hero-heading {
  position: absolute;
  top: 50%;
  width: $business-accounts-homepage-hero-heading-width;
  transform: translateY(-50%);
}

.business-accounts-homepage__hero-heading u,
.business-accounts-homepage__hero-heading--mobile u {
  position: relative;
  text-decoration: none;
  white-space: nowrap;
}

.business-accounts-homepage__hero-heading u::before,
.business-accounts-homepage__hero-heading--mobile u::before {
  position: absolute;
  top: unit(13);
  right: unit(-1);
  bottom: unit(-8);
  left: unit(-1);
  background: url("../../../next/public/assets/business-accounts/line-brush.svg");
  background-repeat: no-repeat;
  background-size: 100% unit(2);
  content: "";

  @include respond-to(phones) {
    top: unit(8);
    background-size: 100% unit(2);
  }
}

.business-accounts-homepage__hero-heading-container {
  width: $business-accounts-homepage-hero-heading-container-width;
  padding: 0
    $business-accounts-homepage-hero-heading-container-horizontal-padding;
  margin: 0 auto;
}

.business-accounts-homepage__cta {
  display: inline-block;
  min-width: $business-accounts-homepage-cta-min-width;
}

.business-accounts-homepage__banner {
  position: relative;
  display: flex;
  width: 100%;
  min-height: $business-accounts-homepage-banner-height;
  background-color: $business-accounts-homepage-banner-background-color;
  justify-content: center;
  align-items: center;
}

.bottom-navigation-footer {
  @include sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background-color: palette(greyscale-level-6);
  border-top: 1px solid $divider-color;
}

.c-input__value--justified {
  justify-content: space-between;
}

.bottom-border-none {
  border-bottom: 0 !important;
}

.business-accounts__pro-integrations {
  padding-top: unit(16);
  padding-bottom: unit(16);
  width: 100vw;
  background-color: palette(primary-dark);
}

.business-accounts__pro-integrations-inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond-to(desktops) {
    max-width: unit(240);
    flex-direction: row;
    justify-content: space-between;
  }
}

.business-accounts__pro-integrations-content {
  flex: 1;
}

.business-accounts__pro-integrations-image {
  flex: 1;
  display: none;

  @include respond-to(desktops) {
    display: block;
  }
}

.business-accounts__pro-integrations-cta {
  @include respond-to(desktops) {
    max-width: unit(50);
  }
}