.closet-promo-stats {
  display: grid;
  grid:
    "header"
    "insights"
    "actions";
  column-gap: unit(4);
  row-gap: unit(4);

  @include respond-to(tablets-up) {
    row-gap: unit(6);
  }

  @include respond-to(desktops) {
    grid:
      "header header"
      "insights actions"
      / 1fr 1fr;
  }

  @include respond-to(wide) {
    grid:
      "header header header"
      "insights insights actions"
      / 1fr 1fr 1fr;
  }
}

.closet-promo-stats__header {
  grid-area: header;
}

.closet-promo-stats__insights {
  grid-area: insights;
  min-width: 0;
}

.closet-promo-stats__actions {
  grid-area: actions;
}

.closet-promo-stats__promoted-dot {
  @include size(unit(2));
  border-radius: 50%;
  display: inline-block;
  background-color: palette(primary-default);
}

.closet-promo-stats__organic-dot {
  @include size(unit(2));
  border-radius: 50%;
  display: inline-block;
  background-color: palette(greyscale-level-3);
}

.closet-promo-stats-v2 {
  display: grid;
  grid:
    "header"
    "views"
    "activity";
    row-gap: unit(4);

  @include respond-to(tablets-up) {
    row-gap: unit(6);
    column-gap: unit(6);
  }

  @include respond-to(desktops) {
    grid:
      "header header header"
      "views views activity"
      / 1fr 1fr 1fr;
  }
}

.closet-promo-stats-v2__header {
  grid-area: header;
}

.closet-promo-stats-v2__views {
  grid-area: views;
  min-width: 0;
}

.closet-promo-stats-v2__activity {
  grid-area: activity;

  & > * {
    box-sizing: border-box;
    height: 100%;
  }
}

.closet-promo-stats-v2__activity-cards {
  display: flex;
  gap: unit(4);

  @include respond-to(tablets-up) {
    flex-direction: column;
  }

  & > * {
    flex-basis: 50%;
  }
}
